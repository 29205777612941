import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import SideNavigation from './SideNavigation';
import UserGroupContext from '../../contexts/UserGroupContext';
import Dialog from '@mui/material/Dialog';
import RetailerSelection from './RetailerSelection';

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #707070;
`;

const Item = styled.div`
  background-color: white;
  border: 1px solid;
  border-radius: 4px;
  border-color: #ced7e0;
`;

const MainContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  color: #707070;
  background-color: #e7ebf0;
`;

const MainContent = styled.div`
  width: 100%;
  height: 100%;
`;

const MainLayout = ({ children }) => {
  const { supplierDomain, userGroup } = useContext(UserGroupContext);
  const [changeStore, setChangeStore] = useState(false);

  const handleChangeStore = open => {
    console.log('haha', open);
    setChangeStore(open);
  };

  return supplierDomain && userGroup.userGroupId ? (
    <Container>
      <Item>
        Current Store: {`${userGroup.business} (${userGroup.userGroupId})`}
        <Button variant="outlined" onClick={() => handleChangeStore(true)}>
          Change
        </Button>
      </Item>
      <MainContainer>
        <SideNavigation />
        <MainContent>{children}</MainContent>
      </MainContainer>
      <Dialog onClose={() => handleChangeStore(false)} open={changeStore}>
        <RetailerSelection closeOnRetailerChange={() => handleChangeStore(false)} />
      </Dialog>
    </Container>
  ) : (
    <div />
  );
};

export default MainLayout;
