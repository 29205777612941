import { isAuthenticated } from '../services/cognito';

export const isLoggedIn = async () => {
  const result = await isAuthenticated();
  return result;
};

export const getUrls = (environment, admintoolUrl) => {
  switch (environment) {
    case 'stg-dev-1':
    case 'stg-dev-2':
    case 'stg-dev-3':
    case 'stg-dev-4':
    case 'stg-dev-5':
      return {
        app: `https://4.${environment}.whitechstage.com`,
        admintool:
          admintoolUrl || `https://${environment}.whitechstage.com/adminTool/online_admin.php`,
      };
    default:
      return {
        app: `https://4.${environment}.whitechstage.com`,
        admintool: admintoolUrl || `https://stg-dev-6.whitechstage.com/adminTool/online_admin.php`,
      };
  }
};

export const getPipelineUrl = ({ pipelineNo, project }) =>
  `https://${process.env.REACT_APP_GITLAB_DOMAIN}/whitech-group/development/reimagine/${project}/-/pipelines/${pipelineNo}`;
