import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import styled from 'styled-components';

const StyledFormControl = styled(FormControl)`
  && {
    min-width: 60px;
    margin-right: 35px;
    margin-top: 5px;
  }
`;

const VersionSelect = ({ loading, environment, versions, version, onVersionSelect }) => {
  const [value, setValue] = useState(-1);

  useEffect(() => {
    const valueIndex = versions.findIndex(name => name === version);
    setValue(valueIndex);
    if (versions[valueIndex]) {
      onVersionSelect(versions[valueIndex]);
    }
  }, [version, versions]);

  const handleChange = event => {
    const index = event.target.value;
    if (index >= 0) {
      setValue(index);
      onVersionSelect(versions[index]);
    }
  };

  return (
    <StyledFormControl>
      <Select
        disabled={loading}
        key={`${environment}-version-select`}
        native
        value={value}
        onChange={handleChange}
        inputProps={{
          name: 'version',
          id: 'version-native-helper',
        }}
      >
        <option value="">Select a version to deploy</option>
        {versions.map((version, index) => (
          <option key={`${environment}-${version}`} value={index}>
            {version}
          </option>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default VersionSelect;
