import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Environments from './routes/EnvironmentScreen';
import Approvals from './routes/ApprovalScreen';
import Monitoring from './routes/Monitoring/MonitoringScreen';
import KioskInfo from './routes/KioskInfo';
import CognitoSignInScreen from './routes/CognitoSignInScreen';
import ResetPassword from './routes/ResetPassword';
import ForgotPassword from './routes/ForgotPassword';
import ForceChangePassword from './routes/ForceChangePassword';
import KioskBuild from './routes/KioskBuildScreen';
import Automation from './routes/AutomationScreen';
import MachinesOffline from './routes/KioskInfo/MachinesOffline';
import MachinesDetails from './routes/KioskInfo/MachinesDetails';
import './App.css';
import UserGroupContext from './contexts/UserGroupContext';

const App = () => {
  const [userGroup, setUserGroup] = useState();
  const [supplierDomain, setSupplierDomain] = useState();

  return (
    <UserGroupContext.Provider
      value={{ userGroup, setUserGroup, supplierDomain, setSupplierDomain }}
    >
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Navigate replace to="/dashboard/environments" />} />
            <Route restricted={false} element={<CognitoSignInScreen />} path="/login" exact />
            <Route restricted={false} element={<ResetPassword />} path="/resetPassword" exact />
            <Route restricted={false} element={<ForgotPassword />} path="/forgotPassword" exact />
            <Route
              restricted={false}
              element={<ForceChangePassword />}
              path="/forceChangePassword"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <Environments />
                </PrivateRoute>
              }
              path="/dashboard/environments"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <Approvals />
                </PrivateRoute>
              }
              path="/dashboard/approvals"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <Automation />
                </PrivateRoute>
              }
              path="/dashboard/automation-test"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <KioskBuild />
                </PrivateRoute>
              }
              path="/dashboard/kiosk-build"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <Monitoring />
                </PrivateRoute>
              }
              path="/dashboard/monitoring"
            />
            <Route
              element={
                <PrivateRoute>
                  <KioskInfo />
                </PrivateRoute>
              }
              path="/dashboard/kiosk-info"
            />
            <Route
              element={
                <PrivateRoute>
                  <MachinesOffline />
                </PrivateRoute>
              }
              path="/dashboard/kiosk-info/status"
            />
            <Route
              element={
                <PrivateRoute>
                  <MachinesDetails />
                </PrivateRoute>
              }
              path="/dashboard/kiosk-info/details"
            />
          </Routes>
        </BrowserRouter>
      </div>
    </UserGroupContext.Provider>
  );
};

export default App;
