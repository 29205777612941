import React from 'react';
import styled from 'styled-components';
import MonitoringSideNavigation from './MonitoringSideNavigation';
import MonitoringContent from './MonitoringContent';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
`;

const MonitoringScreen = () => {
  return (
    <Root>
      <Container>
        <MonitoringSideNavigation />
        <MonitoringContent />
      </Container>
    </Root>
  );
};

export default MonitoringScreen;
