import React, { useState, useEffect } from 'react';
import { getLambdaMonitoring } from '../../../services';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import LambdaCharts from './LambdaCharts';
import './index.css';

const Root = styled.div`
  height: 100%;
  width: 100%;
`;

const MonitoringLambda = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const _getStats = async () => {
      setLoading(true);
      const { data } = await getLambdaMonitoring();
      setData(data);
      setLoading(false);
    };

    _getStats();
  }, []);

  return loading ? (
    <CircularProgress color={'white'} />
  ) : (
    <Root>
      {(data || []).map(elem => (
        <LambdaCharts
          key={elem.region}
          region={elem.region}
          data={{
            unreservedConcurrentExecutions: elem.unreservedConcurrentExecutions,
            unreservedConcurrentExecutionStats: elem.unreservedConcurrentExecutionStats,
          }}
        />
      ))}
    </Root>
  );
};

export default MonitoringLambda;
