import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import styled, { css } from 'styled-components';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import DeploymentStatus from './DeploymentStatus';

// import Table from '@mui/material/Table';
// import Thead from '@mui/material/Thead';
// import TableCell from '@mui/material/TableCell';
// import Tr from '@mui/material/Tr';

// import Tbody from '@mui/material/Tbody';

const Root = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const Record = styled(Paper)`
  && {
    border-radius: 0px;
  }
`;

const StyledAccordion = styled(Accordion)`
  && {
    &.Mui-expanded {
      margin-top: 0px;
    }
    &:last-child {
      border-radius: 0px;
    }
  }
`;

const StyledTh = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Th {...props}>{children}</Th>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  .MuiTypography-body1 {
    font-weight: 600;
  }
`;

const StyledTd = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Td {...props}>{children}</Td>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;

const StyledTable = styled(Table)`
  padding: 10px;

  &.responsiveTable tbody tr {
    border: none !important;
  }
`;

const StyledThead = styled(Thead)`
  padding-bottom: 10px;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    flex-direction: column;
    max-height: 100px;
    overflow: auto;
  }
`;

const maxWidth = 200;

const ApprovalRow = ({ record = {}, onApprove, onDeploy, onFail, onComplete, userInfo }) => {
  const {
    approvals = [],
    environment,
    currentCommit = {},
    audit = [],
    currentVersion,
    pendingVersion,
    deploymentStatusReimagine,
    deploymentStatusPhp,
    previousCommitHash,
  } = record;

  const [autoDeploy, setAutoDeploy] = useState(true);
  const allowedToApprove = userInfo.canApprove;
  const hasAlreadyApproved =
    approvals.findIndex(approver => approver.authorId === userInfo.userId) > -1;

  return (
    <Root>
      <Record>
        <StyledTable>
          <StyledThead>
            <Tr>
              <StyledTh width={200}>
                <Typography>Environment</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Commit</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Current Version</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Pending Version</Typography>
              </StyledTh>
              {previousCommitHash !== currentCommit.commitHash ? (
                <>
                  <StyledTh maxWidth={maxWidth}>
                    <Typography>Approvals</Typography>
                  </StyledTh>
                  {approvals.length > 1
                    ? allowedToApprove && (
                        <>
                          <StyledTh textAlign="center">
                            <Typography>Auto Deploy</Typography>
                          </StyledTh>
                          <StyledTh textAlign="center">
                            <Typography>Deploy Reimagine</Typography>
                          </StyledTh>
                          <StyledTh textAlign="center">
                            <Typography>Deploy PHP</Typography>
                          </StyledTh>
                        </>
                      )
                    : allowedToApprove && (
                        <StyledTh textAlign="center" maxWidth={100}>
                          <Typography>Actions</Typography>
                        </StyledTh>
                      )}
                </>
              ) : (
                <StyledTh />
              )}
            </Tr>
          </StyledThead>
          <Tbody>
            <Tr>
              <StyledTd width={200}>
                <Typography>{environment}</Typography>
              </StyledTd>
              <StyledTd width={200}>
                <a href={currentCommit.commitUrl} target="_blank" rel="noreferrer">
                  <Typography>View Commit</Typography>
                </a>
              </StyledTd>
              <StyledTd width={200}>
                <Typography>{currentVersion}</Typography>
              </StyledTd>
              <StyledTd width={200}>
                <Typography>{pendingVersion}</Typography>
              </StyledTd>
              {previousCommitHash !== currentCommit.commitHash ? (
                <>
                  <StyledTd maxWidth={maxWidth}>
                    {approvals && approvals.length ? (
                      <Typography>
                        {approvals.map(({ authorName }) => authorName).join(', ')} has approved.{' '}
                        {approvals.length < 2 &&
                          `Require ${2 - approvals.length} more approver${
                            2 - approvals.length > 1 ? 's' : ''
                          }.`}
                      </Typography>
                    ) : (
                      <Typography>No approvals. Require 2 approvals.</Typography>
                    )}
                  </StyledTd>
                  {approvals.length > 1
                    ? allowedToApprove && (
                        <>
                          <StyledTd textAlign="center">
                            <Checkbox
                              checked={autoDeploy}
                              onClick={() => setAutoDeploy(!autoDeploy)}
                            />
                          </StyledTd>
                          <StyledTd textAlign="center">
                            <DeploymentStatus
                              deploymentEnvironment="reimagine"
                              deploymentStatus={deploymentStatusReimagine}
                              environment={environment}
                              commitHash={currentCommit.commitHash}
                              onDeploy={onDeploy}
                              autoDeploy={autoDeploy}
                              onFail={onFail}
                              onComplete={onComplete}
                            />
                          </StyledTd>
                          <StyledTd textAlign="center">
                            <DeploymentStatus
                              deploymentEnvironment="php"
                              deploymentStatus={deploymentStatusPhp}
                              environment={environment}
                              commitHash={currentCommit.commitHash}
                              onDeploy={onDeploy}
                              onFail={onFail}
                              onComplete={onComplete}
                            />
                          </StyledTd>
                        </>
                      )
                    : allowedToApprove && (
                        <StyledTd
                          textAlign="center"
                          maxWidth={100}
                          style={{
                            width: 200,
                          }}
                        >
                          {hasAlreadyApproved ? (
                            <Typography>Approved</Typography>
                          ) : (
                            <Button
                              onClick={() =>
                                onApprove({
                                  environment,
                                  commitHash: currentCommit.commitHash,
                                })
                              }
                              variant="contained"
                              color="primary"
                            >
                              Approve
                            </Button>
                          )}
                        </StyledTd>
                      )}
                </>
              ) : (
                <StyledTd>
                  <Typography>No new release to approve</Typography>
                </StyledTd>
              )}
            </Tr>
          </Tbody>
        </StyledTable>
      </Record>
      {audit.length ? (
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>History</Typography>
          </AccordionSummary>
          <StyledAccordionDetails>
            {audit.map(history => (
              <Typography key={history.timestamp}>
                {new Date(history.timestamp).toLocaleString()} - {history.user} initiated{' '}
                {String(history.action).toUpperCase()} action for commit{' '}
                {history.commitHash.substring(0, 8)}
              </Typography>
            ))}
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : null}
    </Root>
  );
};

export default ApprovalRow;
