import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import styled, { css } from 'styled-components';

import { getGitlabStatuses } from '../services';
import { isLoggedIn, getUrls } from '../utils';

import {
  deployGitlabPipeline,
  cleanGitlabPipeline,
  notifyReadyToTest,
  rejectBranch,
  approveBranch,
} from '../services';
import BranchSelect from './BranchSelect';

const headerColours = {
  cleaning: 'orange',
  free: 'green',
  deploying: 'orange',
  testing: 'cornflowerblue',
  rejected: 'mediumpurple',
  failed_deploying: 'black',
  failed_cleaning: 'black',
};

const StyledCard = styled(Card)`
  width: 400px;
  margin: 0 10px 20px;
`;

const StyledCardHeader = styled(CardHeader)`
  color: white;

  ${props =>
    css`
      background-color: ${props.loading ? 'grey' : headerColours[props.environment] || 'orangered'};
    `}
`;

const BranchCard = ({ status, branches, id, setStatuses, userInfo }) => {
  const [selectedBranch, setSelectedBranch] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const onBranchSelect = (environment, branch) => {
    const { name, commitHash } = branch;
    setSelectedBranch({
      branch: name,
      commitHash,
      environment,
    });
  };

  const checkAuth = fnc => {
    if (isLoggedIn()) {
      return fnc();
    } else {
      setRedirect(true);
    }
  };

  const onSend = async () => {
    const userId = userInfo.userId;
    const userName = userInfo.username;
    if (selectedBranch && selectedBranch.branch && selectedBranch.commitHash) {
      setLoading(true);
      await deployGitlabPipeline({
        ...selectedBranch,
        authorName: userName,
        authorId: userId,
      });
      const result = await getGitlabStatuses();
      setStatuses(result.data);
      setLoading(false);
    }
  };

  const onHandleRestore = async environment => {
    setLoading(true);
    await cleanGitlabPipeline(environment);
    const result = await getGitlabStatuses();
    setStatuses(result.data);
    setLoading(false);
  };

  const onReadyToTest = async ({ environment, branch, commitHash }) => {
    if (environment && branch && commitHash) {
      setLoading(true);
      await notifyReadyToTest({ environment, branch, commitHash });
      const result = await getGitlabStatuses();
      setStatuses(result.data);
      setLoading(false);
    }
  };

  const onApprove = async ({ environment, branch, commitHash }) => {
    if (environment && branch && commitHash) {
      setLoading(true);
      await approveBranch({ environment, branch, commitHash });
      await cleanGitlabPipeline(environment);
      const result = await getGitlabStatuses();
      setStatuses(result.data);
      setLoading(false);
    }
  };

  const onReject = async ({ environment, branch, commitHash }) => {
    if (environment && branch && commitHash) {
      setLoading(true);
      await rejectBranch({ environment, branch, commitHash });
      const result = await getGitlabStatuses();
      setStatuses(result.data);
      setLoading(false);
    }
  };

  const {
    environmentStatus,
    purpose,
    branch,
    environment,
    commitHash,
    authorName,
    admintoolUrl,
  } = status;
  const urls = getUrls(environment, admintoolUrl);
  const lowerCaseEnvironmentStatus = environmentStatus.toLowerCase();

  const parsedLowerCaseEnvironmentStatus = lowerCaseEnvironmentStatus.replace('failed_', '');

  const isRedeployable = ['deploying', 'deployed', 'rejected'].includes(
    parsedLowerCaseEnvironmentStatus
  );

  if (redirect) {
    return <Navigate to="/" />;
  }

  return (
    <StyledCard key={id}>
      <StyledCardHeader
        loading={loading}
        environment={lowerCaseEnvironmentStatus}
        title={`${environment} - ${String(environmentStatus).toUpperCase()}`}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Purpose: {purpose}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Author: {authorName || ''}
        </Typography>
        <BranchSelect
          environment={environment}
          branches={branches}
          branch={branch}
          onBranchSelect={onBranchSelect}
        />
        <div style={{ paddingTop: 20 }}>
          <Button variant="contained" style={{ marginRight: 10 }} href={urls.app} target="_blank">
            App
          </Button>
          {/*<Button variant="contained" href={urls.admintool} target="_blank">*/}
          {/*  Admintool*/}
          {/*</Button>*/}
        </div>
      </CardContent>
      <CardActions disableSpacing>
        {parsedLowerCaseEnvironmentStatus !== 'cleaning' && (
          <div>
            <Button
              size="small"
              color="primary"
              onClick={() => checkAuth(() => onSend())}
              disabled={loading}
            >
              {isRedeployable ? 'Redeploy' : 'Deploy'}
            </Button>
          </div>
        )}
        <div
          style={{
            marginRight: 10,
          }}
        >
          <Button
            size="small"
            color="secondary"
            disabled={loading}
            onClick={() => checkAuth(() => onHandleRestore(environment))}
          >
            {parsedLowerCaseEnvironmentStatus !== 'cleaning' ? 'Clean' : 'Retry Clean'}
          </Button>
        </div>
        {loading && <CircularProgress size={16} />}
        {parsedLowerCaseEnvironmentStatus === 'deployed' && (
          <div
            style={{
              marginLeft: 'auto',
            }}
          >
            <Button
              size="small"
              color="primary"
              disabled={loading}
              onClick={() => checkAuth(() => onReadyToTest({ environment, branch, commitHash }))}
            >
              Ready to Test
            </Button>
          </div>
        )}
        {parsedLowerCaseEnvironmentStatus === 'testing' && (
          <div
            style={{
              marginLeft: 'auto',
            }}
          >
            <Button
              size="small"
              color="primary"
              disabled={loading}
              onClick={() => checkAuth(() => onApprove({ environment, branch, commitHash }))}
            >
              Approve
            </Button>
            <Button
              size="small"
              color="secondary"
              disabled={loading}
              onClick={() => checkAuth(() => onReject({ environment, branch, commitHash }))}
            >
              Reject
            </Button>
          </div>
        )}
      </CardActions>
    </StyledCard>
  );
};

export default BranchCard;
