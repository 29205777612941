import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Link } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/cognito';
import TextField from '../components/TextField';
import UserForm from '../components/UserForm';

const ErrorDiv = styled.div`
  color: #ff0000;
  font-size: 1rem;
`;

const StyledLink = styled(Link)`
  font-size: 1rem;
`;

const validationSchema = yup.object({
  username: yup.string('Enter your username').required('Username is required'),
  password: yup.string('Enter your password').required('Password is required'),
});

const CognitoSignInScreen = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const doLogin = async (values, { setSubmitting }) => {
    setErrorMsg('');
    const result = await login(values);
    if (result.code === 'NotAuthorizedException') {
      setErrorMsg(result.message);
    } else if (result?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      navigate('/forceChangePassword', {
        state: {
          username: values.username,
          password: values.password,
        },
      });
    } else {
      navigate('/dashboard/environments');
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: doLogin,
  });

  return (
    <UserForm formik={formik} title="Please Login" autoComplete="on">
      <TextField fullWidth id="username" label="Username" formik={formik} />
      <TextField fullWidth type="password" id="password" label="Password" formik={formik} />
      {errorMsg !== '' && <ErrorDiv>{errorMsg}</ErrorDiv>}
      <div>
        <StyledLink
          href="#"
          onClick={() => {
            navigate('/forgotPassword');
          }}
        >
          Forgot Password
        </StyledLink>
      </div>

      <div>
        <Button variant="contained" disabled={formik.isSubmitting} type="submit">
          Login
        </Button>
      </div>
    </UserForm>
  );
};

export default CognitoSignInScreen;
