import { authAxios } from './authAxios';

const baseURL = process.env.REACT_APP_API_URL;

const oauth = async code => {
  return authAxios().post(
    `${baseURL}/api/gitlab/oauth`,
    {
      code,
      redirectUri: `${window.location.protocol}//${window.location.host}/slack/auth`,
    },
    {
      withCredentials: true,
    }
  );
};

const getGitlabStatuses = () =>
  authAxios().get(`${baseURL}/api/gitlab/statuses`, {
    withCredentials: true,
  });
const getGitlabBranches = () =>
  authAxios().get(`${baseURL}/api/gitlab/branches`, {
    withCredentials: true,
  });

const getGitlabStableBranches = () =>
  authAxios().get(`${baseURL}/api/gitlab/branches/stable`, {
    withCredentials: true,
  });

const cleanGitlabPipeline = environment =>
  authAxios().post(
    `${baseURL}/api/gitlab/clean`,
    {
      environment,
    },
    {
      withCredentials: true,
    }
  );
const deployGitlabPipeline = ({ environment, branch, commitHash, authorName, authorId }) =>
  authAxios().post(
    `${baseURL}/api/gitlab/deploy`,
    {
      environment,
      commitHash,
      branch,
      authorId,
      authorName,
    },
    {
      withCredentials: true,
    }
  );

const notifyReadyToTest = ({ environment, branch, commitHash }) =>
  authAxios().post(
    `${baseURL}/api/gitlab/rtt`,
    {
      environment,
      branch,
      commitHash,
    },
    {
      withCredentials: true,
    }
  );

const rejectBranch = ({ environment, branch, commitHash }) =>
  authAxios().post(
    `${baseURL}/api/gitlab/reject`,
    {
      environment,
      branch,
      commitHash,
    },
    {
      withCredentials: true,
    }
  );

const approveBranch = ({ environment, branch, commitHash }) =>
  authAxios().post(
    `${baseURL}/api/gitlab/approve`,
    {
      environment,
      branch,
      commitHash,
    },
    {
      withCredentials: true,
    }
  );

const getDashboardApprovals = environment => {
  return environment === 'prod'
    ? authAxios().get(`${baseURL}/api/approvals/production`, {
        withCredentials: true,
      })
    : authAxios().get(`${baseURL}/api/approvals/staging`, {
        withCredentials: true,
      });
};

const getJiraVersions = () =>
  authAxios().get(`${baseURL}/api/jira/versions`, {
    withCredentials: true,
  });

const approveDeployment = ({ environment, userName, commitHash }) =>
  authAxios().post(
    `${baseURL}/api/approvers/approve`,
    {
      environment,

      userName,
      commitHash,
    },
    {
      withCredentials: true,
    }
  );

const deployEnvironment = ({
  environment,
  userName,
  commitHash,
  deploymentEnvironment,
  autoDeploy = true,
}) =>
  authAxios().post(
    `${baseURL}/api/approvers/deploy`,
    {
      deploymentEnvironment,
      environment,
      userName,
      commitHash,
      releaseToProduction: autoDeploy ? 'true' : 'manual',
    },
    {
      withCredentials: true,
    }
  );

const retryFailedDeployEnvironment = ({
  environment,
  userName,
  commitHash,
  deploymentEnvironment,
}) =>
  authAxios().post(
    `${baseURL}/api/approvers/deploy/retry`,
    {
      deploymentEnvironment,
      environment,
      userName,
      commitHash,
    },
    {
      withCredentials: true,
    }
  );

const completeDeployment = ({ environment, userName, commitHash, deploymentEnvironment }) =>
  authAxios().post(
    `${baseURL}/api/approvers/deploy/complete`,
    {
      deploymentEnvironment,
      environment,
      userName,
      commitHash,
    },
    {
      withCredentials: true,
    }
  );

const logout = () =>
  authAxios().post(
    `${baseURL}/api/logout`,
    {},
    {
      withCredentials: true,
    }
  );

const prepareMergeRequest = ({ environment, version, isProduction = false }) => {
  return authAxios().post(
    `${baseURL}/api/gitlab/mr/prepare`,
    {
      environment,
      version,
      isProduction,
    },
    {
      withCredentials: true,
    }
  );
};

const acceptMergeRequest = ({ environment, isProduction = false }) =>
  authAxios().post(
    `${baseURL}/api/gitlab/mr/accept`,
    {
      environment,
      isProduction,
    },
    {
      withCredentials: true,
    }
  );

const checkMergeRequest = ({ environment, isProduction = false }) =>
  authAxios().post(
    `${baseURL}/api/gitlab/mr/check`,
    {
      environment,
      isProduction,
    },
    {
      withCredentials: true,
    }
  );

const getECSMonitoring = () =>
  authAxios().get(`${baseURL}/api/monitoring/ecs`, {
    withCredentials: true,
  });

const getLambdaMonitoring = () =>
  authAxios().get(`${baseURL}/api/monitoring/lambda`, {
    withCredentials: true,
  });

const getDashboardMonitoring = () =>
  authAxios().get(`${baseURL}/api/monitoring/dashboard`, {
    withCredentials: true,
  });

const getKioskBuild = (type = 'development') =>
  authAxios().get(`${baseURL}/api/kiosk/build/${type}`, {
    withCredentials: true,
  });

const initiateKioskBuildByBranch = ({ branch, version, supplierCode }) =>
  authAxios().post(
    `${baseURL}/api/kiosk/build/initiate`,
    {
      branch,
      version,
      supplierCode,
    },
    {
      withCredentials: true,
    }
  );

const addBranch = ({ branch }) =>
  authAxios().post(
    `${baseURL}/api/kiosk/branch/add`,
    {
      branch,
    },
    {
      withCredentials: true,
    }
  );

const hideBranch = ({ branch }) =>
  authAxios().post(
    `${baseURL}/api/kiosk/branch/hide`,
    {
      branch,
    },
    {
      withCredentials: true,
    }
  );

const startAutomationTest = ({ environment, serverCode, serverEnv }) =>
  authAxios().post(
    `${baseURL}/api/approvers/automation-test/start`,
    {
      environment,
      serverCode,
      serverEnv,
    },
    {
      withCredentials: true,
    }
  );
const cancelAutomationPipeline = ({ environment, automationPipelineNo }) =>
  authAxios().post(
    `${baseURL}/api/approvers/automation-test/pipeline/${automationPipelineNo}/cancel`,
    {
      environment,
    },
    {
      withCredentials: true,
    }
  );

const cancelPipeline = ({ branch, pipelineNo }) =>
  authAxios().post(
    `${baseURL}/api/kiosk/pipeline/${pipelineNo}/cancel`,
    {
      branch,
    },
    {
      withCredentials: true,
    }
  );

export {
  oauth,
  getGitlabStatuses,
  getGitlabBranches,
  getGitlabStableBranches,
  deployGitlabPipeline,
  cleanGitlabPipeline,
  notifyReadyToTest,
  rejectBranch,
  approveBranch,
  getJiraVersions,
  getDashboardApprovals,
  approveDeployment,
  deployEnvironment,
  retryFailedDeployEnvironment,
  completeDeployment,
  logout,
  prepareMergeRequest,
  acceptMergeRequest,
  checkMergeRequest,
  getECSMonitoring,
  getDashboardMonitoring,
  getLambdaMonitoring,
  initiateKioskBuildByBranch,
  getKioskBuild,
  addBranch,
  hideBranch,
  startAutomationTest,
  cancelPipeline,
  cancelAutomationPipeline,
};
