import React, { useState, useEffect } from 'react';
import { getDashboardMonitoring } from '../../../services';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { STATUS } from '../constants';

const ServiceData = styled(({ status, ...props }) => <div {...props} />)`
  border: 1px solid white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  background: ${props =>
    props.status === STATUS.DANGER
      ? 'rgba(255, 0, 0, 0.1)'
      : props.status === STATUS.WARNING
      ? 'rgba(255, 255, 0, 0.1)'
      : 'rgba(0, 255, 0, 0.1)'};
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Header = styled.div`
  font-size: 1em;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
  top: -15px;
  position: relative;
  text-transform: uppercase;
`;

const Message = styled.div`
  margin-top: 10px;
  font-size: 0.8em;
  position: relative;
`;

const ViewButton = styled(NavLink)`
  display: block;
  position: relative;
  margin-top: 30px;
  padding: 10px;
  border: 1px solid white;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.5em;
`;

const MonitoringDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const _getStats = async () => {
      setLoading(true);
      const { data } = await getDashboardMonitoring();
      setData(data);
      setLoading(false);
    };

    _getStats();
  }, []);

  return loading ? (
    <CircularProgress color={'white'} />
  ) : (
    <Root>
      {data.map(datum => (
        <ServiceData status={datum.status}>
          <Header>{datum.serviceType}</Header>
          <Message>{datum.message}</Message>
          <ViewButton to={`/dashboard/monitoring/${datum.serviceType}`}>View</ViewButton>
        </ServiceData>
      ))}
    </Root>
  );
};

export default MonitoringDashboard;
