import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import RetailerSelection from './RetailerSelection';
import Overview from './Overview';
import UserGroupContext from '../../contexts/UserGroupContext';
const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #707070;
`;

const KioskInfo = () => {
  const { supplierDomain, userGroup } = useContext(UserGroupContext);

  // let navigate = useNavigate();
  // return navigate('/dashboard/kiosk-info/overview');
  // useEffect(() => {
  //   console.log('haha');
  //   // return navigate('/dashboard/kiosk-info/overview');
  //   if (supplierDomain && userGroupId) {
  //     console.log('haha2');
  //     return navigate('/dashboard/kiosk-info/overview');
  //   }
  // }, [userGroupId, supplierDomain]);

  return <Root>{!supplierDomain || !userGroup ? <RetailerSelection /> : <Overview />}</Root>;
};

export default KioskInfo;
