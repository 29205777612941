import React, { useEffect, useState } from 'react';
import { isNil, isEmpty } from 'ramda';
import styled from 'styled-components';
import {
  getDashboardApprovals,
  approveDeployment,
  deployEnvironment,
  retryFailedDeployEnvironment,
  completeDeployment,
} from '../../../services';
import ApprovalRow from './ApprovalRow';
// eslint-disable-next-line import/no-webpack-loader-syntax
import AxiosWorker from 'workerize-loader!../../../workers/axios-worker';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProductionApprovals = ({ userInfo }) => {
  const [approvals, setApprovals] = useState([]);
  const [worker, setWorker] = useState(null);

  const onApprove = async ({ environment, commitHash }) => {
    const userName = userInfo.username;

    await approveDeployment({
      userName,
      environment,
      commitHash,
    });
    const result = await getDashboardApprovals('prod');
    setApprovals(result.data || []);
  };

  const onDeploy = async ({
    deploymentEnvironment,
    environment,
    commitHash,
    autoDeploy = true,
  }) => {
    const userName = userInfo.username;

    await deployEnvironment({
      deploymentEnvironment,
      userName,
      environment,
      commitHash,
      autoDeploy,
    });

    const result = await getDashboardApprovals('prod');
    setApprovals(result.data || []);
  };

  const onFail = async ({ deploymentEnvironment, environment, commitHash }) => {
    const userName = userInfo.username;

    await retryFailedDeployEnvironment({
      deploymentEnvironment,
      userName,
      environment,
      commitHash,
    });
    const result = await getDashboardApprovals('prod');
    setApprovals(result.data || []);
  };

  const onComplete = async ({ deploymentEnvironment, environment, commitHash }) => {
    const userName = userInfo.username;

    await completeDeployment({
      deploymentEnvironment,
      userName,
      environment,
      commitHash,
    });
    const result = await getDashboardApprovals('prod');
    setApprovals(result.data || []);
  };

  useEffect(() => {
    const getApprovals = async () => {
      const result = await getDashboardApprovals('prod');
      setApprovals(result.data || []);
    };
    getApprovals();

    const axiosWorkerInstance = AxiosWorker();
    setWorker(axiosWorkerInstance);
    const axiosWorkerListener = axiosWorkerInstance.addEventListener('message', message => {
      if (message.data && message.data.type === 'axios' && message.data.data) {
        setApprovals(message.data.data || []);
      }
    });

    return () => {
      axiosWorkerInstance.removeEventListener('message', axiosWorkerListener);
    };
  }, []);

  useEffect(() => {
    if (!isNil(userInfo) && !isEmpty(userInfo) && !isNil(worker)) {
      const interval = setInterval(() => {
        worker.getApprovals('prod', userInfo.jwtToken);
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [userInfo, worker]);

  return (
    <Root>
      {approvals.map(approval => (
        <ApprovalRow
          key={approval.environment}
          userInfo={userInfo}
          record={approval}
          onApprove={onApprove}
          onDeploy={onDeploy}
          onFail={onFail}
          onComplete={onComplete}
        />
      ))}
    </Root>
  );
};

export default ProductionApprovals;
