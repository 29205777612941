import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from 'styled-components';
import DevelopBuild from './Develop/DevelopBuild';
import SupplierBuild from './Supplier/SupplierBuild';

const StyledTab = styled(Tab)({
  color: 'white',
});

const EnvironmentRoot = styled.div`
  margin-top: 40px;
  width: 100%;
`;

const KioskBuildEnvironment = ({ userInfo }) => {
  const [stage, setStage] = useState(0);

  const onHandleChange = (_, value) => {
    setStage(value);
  };

  return (
    <>
      <div>
        <Tabs value={stage} onChange={onHandleChange}>
          <StyledTab label="Develop" index={0} />
          <StyledTab label="Staging" index={1} />
          <StyledTab label="Production" index={2} />
        </Tabs>
      </div>
      <EnvironmentRoot>
        {stage === 0 && <DevelopBuild userInfo={userInfo} />}
        {stage === 1 && <SupplierBuild userInfo={userInfo} type="stg" />}
        {stage === 2 && <SupplierBuild userInfo={userInfo} type="prod" />}
      </EnvironmentRoot>
    </>
  );
};

export default KioskBuildEnvironment;
