import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordSubmit } from '../services/cognito';
import TextField from '../components/TextField';
import UserForm from '../components/UserForm';

const ErrorDiv = styled.div`
  color: #ff0000;
  font-size: 1rem;
`;

const ResetPassword = ({ location }) => {
  const navigate = useNavigate();
  const validationSchema = yup.object({
    username: yup.string('Enter your username').required('Username is required'),
    code: yup.string('Enter your Code').required('Code is required'),
    newPassword: yup.string('Enter your password').required('Password is required'),
    confirmNewPassword: yup
      .string('Enter your password')
      .required('Password is required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const resetPassword = async (values, { setSubmitting }) => {
    setErrorMsg('');

    // check confirm password
    const result = await forgotPasswordSubmit(values);
    if (result?.code) {
      setErrorMsg(result.message);
    } else {
      navigate('/dashboard/environments');
    }
  };

  const formik = useFormik({
    initialValues: {
      username: location?.state?.username || '',
      code: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: resetPassword,
  });

  const [errorMsg, setErrorMsg] = useState('');

  return (
    <UserForm formik={formik} title="Reset Password, code in your email">
      <TextField fullWidth id="username" label="Username" formik={formik} />
      <TextField fullWidth id="code" label="Code" formik={formik} />
      <TextField fullWidth id="newPassword" label="New Password" formik={formik} type="password" />
      <TextField
        fullWidth
        id="confirmNewPassword"
        label="Confirm New Password"
        formik={formik}
        type="password"
      />
      {errorMsg !== '' && <ErrorDiv>{errorMsg}</ErrorDiv>}
      <div>
        <Button variant="contained" disabled={formik.isSubmitting} type="submit">
          Reset Password
        </Button>
      </div>
    </UserForm>
  );
};

export default ResetPassword;
