import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNil, isEmpty } from 'ramda';
import Alert from '@mui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';

import EnvironmentRow from './EnvironmentRow';
import {
  startAutomationTest,
  getDashboardApprovals,
  cancelAutomationPipeline,
} from '../../services';

// eslint-disable-next-line import/no-webpack-loader-syntax
import AxiosWorker from 'workerize-loader!../../workers/axios-worker';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const envMapping = {
  staging: 'STAGE',
  production: 'PRODUCTION',
};

const Automation = ({ userInfo, environment }) => {
  const [approvals, setApprovals] = useState([]);
  const [worker, setWorker] = useState(null);
  const [error, setError] = useState(undefined);

  const getApprovals = async value => {
    const result = await getDashboardApprovals(value).catch(() => ({}));
    setApprovals(result.data || []);
  };

  const onstartAutomationTest = async serverEnv => {
    // remove prod- or stg- to get server code
    let serverCode = serverEnv.replace('prod-', '');
    serverCode = serverEnv.replace('stg-', '');
    serverCode = serverCode === 'ffaus02' ? 'FFAU' : serverCode.toUpperCase();

    await startAutomationTest({
      environment: envMapping[environment],
      serverEnv,
      serverCode,
    }).catch(e => {
      setError(e.response.data.message);
    });
    await getApprovals(environment);
  };

  const cancelPipeline = async ({ environment, automationPipelineNo }) => {
    await cancelAutomationPipeline({ environment, automationPipelineNo });
    await getApprovals(environment);
  };

  useEffect(() => {
    getApprovals(environment);

    const axiosWorkerInstance = AxiosWorker();
    setWorker(axiosWorkerInstance);
    const axiosWorkerListener = axiosWorkerInstance.addEventListener('message', message => {
      if (message.data && message.data.type === 'axios' && message.data.data) {
        setApprovals(message.data.data || []);
      }
    });

    return () => {
      axiosWorkerInstance.removeEventListener('message', axiosWorkerListener);
    };
  }, [environment]);

  useEffect(() => {
    if (!isNil(userInfo) && !isEmpty(userInfo) && !isNil(worker)) {
      const interval = setInterval(() => {
        worker.getApprovals(environment, userInfo.jwtToken);
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [userInfo, worker, environment]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(undefined);
  };

  return (
    <Root>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
      {approvals.map(approval => (
        <EnvironmentRow
          key={approval.environment}
          record={approval}
          onstartAutomationTest={onstartAutomationTest}
          handleCancelPipeline={cancelPipeline}
        />
      ))}
    </Root>
  );
};

export default Automation;
