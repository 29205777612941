import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../logo-cropped.png';
import { signOut } from '../services/cognito';

const Root = styled.div`
  padding-bottom: 25px;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Nav = styled(NavLink)`
  color: white;
  margin-right: 10px;
  text-decoration: none;
  border: 3px solid white;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;

  &.active {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

const LogoutNav = styled.button`
  margin-left: 20px;
  color: white;
  margin-right: 10px;
  text-decoration: none;
  border: 3px solid white;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;

  &.active {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

const NavContainer = styled.div``;

const Logo = styled.img`
  height: 60px;
`;

const Navigation = () => {
  const navigate = useNavigate();

  const doSignout = async () => {
    await signOut();
    navigate('/login');
  };
  return (
    <Root>
      <Logo src={logo} alt="Logo" />
      <NavContainer>
        <Nav to="/dashboard/environments">Environments</Nav>
        <Nav to="/dashboard/approvals">Approvals</Nav>
        <Nav to="/dashboard/automation-test">Automation Test</Nav>
        <Nav to="/dashboard/kiosk-build">Kiosk Build</Nav>
        <Nav to="/dashboard/monitoring">Monitoring</Nav>
        <Nav to="/dashboard/kiosk-info">Kiosk Info</Nav>
        <LogoutNav onClick={doSignout}>Logout</LogoutNav>
      </NavContainer>
    </Root>
  );
};

export default Navigation;
