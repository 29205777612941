import React, { useEffect, useState } from 'react';
import { isNil, isEmpty } from 'ramda';
import styled from 'styled-components';

import {
  initiateKioskBuildByBranch,
  addBranch,
  hideBranch,
  cancelPipeline,
  getJiraVersions,
} from '../../../services';
import SelectBranch from '../SelectBranch';
import BuildListItem from '../BuildListItem';

// eslint-disable-next-line import/no-webpack-loader-syntax
import AxiosWorker from 'workerize-loader!../../../workers/axios-worker';

const Root = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const BuildList = ({ userInfo, callBranchesApi, callGetKioskBuildApi, type = 'development' }) => {
  const [worker, setWorker] = useState(null);
  const [branches, setBranches] = useState([]);
  const [builds, setBuilds] = useState([]);
  const [versions, setVersions] = useState([]);

  const getBranches = async () => {
    const result = await callBranchesApi();
    setBranches(result.data || []);
  };

  const getKioskBuild = async () => {
    const result = await callGetKioskBuildApi(type);
    setBuilds(result.data);
  };

  const getVersions = async () => {
    const result = await getJiraVersions();
    setVersions(result.data || []);
  };

  useEffect(() => {
    const axiosWorkerInstance = AxiosWorker();
    setWorker(axiosWorkerInstance);

    const axiosWorkerListener = axiosWorkerInstance.addEventListener('message', message => {
      if (message.data && message.data.type === 'axios' && message.data.data) {
        const { data } = message.data;
        setBuilds(data);
      }
    });

    // get list of branches
    getBranches();
    getKioskBuild();
    getVersions();

    return () => {
      axiosWorkerInstance.removeEventListener('message', axiosWorkerListener);
    };
  }, []);

  useEffect(() => {
    if (!isNil(userInfo) && !isEmpty(userInfo) && !isNil(worker)) {
      const interval = setInterval(() => {
        worker.getKioskBuild(userInfo.jwtToken, type);
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [userInfo, worker, type]);

  const handleBranchSelect = async ({ name }) => {
    await addBranch({ branch: name });
    getBranches();
  };

  const handleBuildKiosk = async ({ branch, version = '', supplierCode = '' }) => {
    await initiateKioskBuildByBranch({ branch, version, supplierCode });
    getKioskBuild();
  };

  const handleHideBranch = async ({ branch }) => {
    await hideBranch({ branch });
    getKioskBuild();
  };

  const handleCancelPipeline = async ({ pipelineNo, branch }) => {
    await cancelPipeline({ branch, pipelineNo });
    getKioskBuild();
  };

  return (
    <Root>
      <SelectBranch
        branches={branches}
        onRefresh={getBranches}
        handleBranchSelect={handleBranchSelect}
      />

      {builds.map(build => (
        <BuildListItem
          key={build.branch}
          record={build}
          versions={versions}
          handleBuildKiosk={handleBuildKiosk}
          handleHideBranch={handleHideBranch}
          handleCancelPipeline={handleCancelPipeline}
        />
      ))}
    </Root>
  );
};

export default BuildList;
