import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: false,
  },
});

export const login = async ({ username, password }) => {
  try {
    const result = await Auth.signIn(username, password);
    sessionStorage.setItem('userCognitoToken', result?.signInUserSession?.idToken?.jwtToken);
    return result;
  } catch (error) {
    console.log('error signing in', error);
    return error;
  }
};

export const completePassword = async ({ username, password, newPassword }) => {
  try {
    const user = await Auth.signIn(username, password);
    const result = await Auth.completeNewPassword(
      user,
      newPassword // the new password
    );
    sessionStorage.setItem('userCognitoToken', result?.signInUserSession?.idToken?.jwtToken);
  } catch (error) {
    console.log('error changing password', error);
    return error;
  }
};

export const getCurrentUser = async () => {
  const result = await Auth.currentSession();
  const {
    idToken: { payload },
    accessToken: { jwtToken },
  } = result;
  const approveGroup =
    payload && payload['cognito:groups']
      ? payload['cognito:groups'].filter(group => group === 'approvers')
      : [];
  const userInfo = {
    username: payload['cognito:username'],
    userId: payload.sub,
    canApprove: approveGroup.length === 1,
    jwtToken,
  };

  return userInfo;
};

export const isAuthenticated = async () => {
  try {
    const result = await Auth.currentSession();
    const exp = result?.idToken?.payload?.exp || 0;

    // validate that it hasn't expired yet
    if (exp === 0 || exp * 1000 < Date.now()) return false;

    sessionStorage.setItem('userCognitoToken', result?.accessToken?.jwtToken);
    return true;
  } catch (error) {
    console.log('error isAuthenticated', error);
    return false;
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
    sessionStorage.removeItem('userCognitoToken');
  } catch (error) {
    console.log('error signOut', error);
  }
};

export const forgotPassword = async ({ username }) => {
  try {
    await Auth.forgotPassword(username);
  } catch (error) {
    console.log('error forgotPassword', error);
  }
};

export const forgotPasswordSubmit = async ({ username, code, newPassword }) => {
  try {
    const result = await Auth.forgotPasswordSubmit(username, code, newPassword);
    return result;
  } catch (error) {
    console.log('error forgotPassword', error);
    return error;
  }
};
