import React from 'react';

import { getGitlabBranches, getKioskBuild } from '../../../services';
import BuildList from './DevelopBuildList';

const DevelopBuild = ({ userInfo }) => (
  <BuildList
    userInfo={userInfo}
    callBranchesApi={getGitlabBranches}
    callGetKioskBuildApi={getKioskBuild}
  />
);

export default DevelopBuild;
