import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Main = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 400px;
  padding: 20px 40px 20px 40px;

  & > div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const TitleDiv = styled.div`
  color: #000;
  font-size: 1.5rem;
`;

const UserForm = ({ children, formik, title, autoComplete = 'off' }) => (
  <Root>
    <h1>Whitech Dashboard</h1>

    <form onSubmit={formik.handleSubmit} autoComplete={autoComplete}>
      <Main>
        <TitleDiv>{title}</TitleDiv>
        {children}
      </Main>
    </form>
  </Root>
);

export default UserForm;
