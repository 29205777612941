import React from 'react';
import { TextField as MUITextField } from '@mui/material';

const TextField = ({ formik, id, label, validateId = id, ...rest }) => (
  <MUITextField
    fullWidth
    id={id}
    name={id}
    label={label}
    value={formik.values[validateId]}
    onChange={formik.handleChange}
    error={formik.touched[validateId] && Boolean(formik.errors[validateId])}
    helperText={formik.touched[validateId] && formik.errors[validateId]}
    {...rest}
  />
);

export default TextField;
