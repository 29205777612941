import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../services/cognito';
import TextField from '../components/TextField';
import UserForm from '../components/UserForm';

const ErrorDiv = styled.div`
  color: #ff0000;
  font-size: 1rem;
`;

const validationSchema = yup.object({
  username: yup.string('Enter your username').required('Username is required'),
});

const ForgotPassword = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const submitHandler = async (values, { setSubmitting }) => {
    setErrorMsg('');
    await forgotPassword(values);
    navigate('/resetPassword', {
      state: { username: values.username },
    });
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });

  return (
    <UserForm formik={formik} title="Forgot Password">
      <TextField fullWidth id="username" label="Username" formik={formik} />
      {errorMsg !== '' && <ErrorDiv>{errorMsg}</ErrorDiv>}
      <div>
        <Button variant="contained" disabled={formik.isSubmitting} type="submit">
          Submit
        </Button>
      </div>
    </UserForm>
  );
};

export default ForgotPassword;
