import React, { useEffect, useState } from 'react';
import { isNil, isEmpty } from 'ramda';
import { getGitlabStatuses, getGitlabBranches } from '../services';
import BranchCard from '../components/BranchCard';
import styled from 'styled-components';
import { getCurrentUser } from '../services/cognito';
// eslint-disable-next-line import/no-webpack-loader-syntax
import AxiosWorker from 'workerize-loader!../workers/axios-worker';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Header = styled.h3`
  text-align: center;
`;

const EnvironmentGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const EnvironmentGroupHeader = styled.div`
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
`;

const EnvironmentScreen = () => {
  const [statuses, setStatuses] = useState([]);
  const [branches, setBranches] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [worker, setWorker] = useState(null);

  useEffect(() => {
    const getStatuses = async () => {
      const result = await getGitlabStatuses();
      setStatuses(result.data || []);
    };
    getStatuses();

    const getBranches = async () => {
      const result = await getGitlabBranches();
      setBranches(result.data || []);
    };
    getBranches();

    const getUserInfo = async () => {
      const result = await getCurrentUser();
      setUserInfo(result);
    };

    getUserInfo();

    const axiosWorkerInstance = AxiosWorker();
    setWorker(axiosWorkerInstance);
    const axiosWorkerListener = axiosWorkerInstance.addEventListener('message', message => {
      console.log('New Message: ', message.data);
      if (message.data && message.data.type === 'axios' && message.data.data) {
        setStatuses(message.data.data || []);
      }
    });

    return () => {
      axiosWorkerInstance.removeEventListener('message', axiosWorkerListener);
    };
  }, []);

  useEffect(() => {
    if (!isNil(userInfo) && !isEmpty(userInfo) && !isNil(worker)) {
      const interval = setInterval(() => {
        worker.getEnvironments(userInfo.jwtToken);
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [userInfo, worker]);

  return (
    <Root>
      <Header>Environments</Header>
      {Object.keys(statuses).map(grouping => (
        <EnvironmentGroup>
          <EnvironmentGroupHeader>{grouping}</EnvironmentGroupHeader>
          <CardContainer>
            {(statuses[grouping] || []).map((status, id) => (
              <BranchCard
                key={status.environment}
                branches={branches}
                status={status}
                id={id}
                setStatuses={setStatuses}
                userInfo={userInfo}
              />
            ))}
          </CardContainer>
        </EnvironmentGroup>
      ))}
    </Root>
  );
};

export default EnvironmentScreen;
