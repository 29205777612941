import React, { useEffect, useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import MainLayout from './MainLayout';
import UserGroupContext from '../../contexts/UserGroupContext';

import { getMachinesInfo, getMachinesVersions } from '../../services/kiosk-info';

const Item = styled.div`
  padding: 5px;
  margin: 10px;
  background-color: white;
  border: 1px solid;
  border-radius: 4px;
  border-color: #ced7e0;
`;

const Title = styled(Typography)`
  font-weight: bold;
`;

const ChartDiv = styled.div`
  margin-top: 10px;
  font-size: 2px;
  cursor: pointer;
  cursor: pointer;
`;

const Overview = () => {
  console.log('OverView');
  const { supplierDomain, userGroup } = useContext(UserGroupContext);
  const navigate = useNavigate();

  const [kioskStatuses, setKioskStatuses] = useState([]);
  const [machineVersions, setMachineVersions] = useState({});

  useEffect(() => {
    const getMachineStatuses = async () => {
      const { online, offline } = await getMachinesInfo({
        supplierDomain,
        userGroupId: userGroup.userGroupId,
      });

      setKioskStatuses([
        { label: 'Online', value: online, color: '#478C5C' },
        { label: 'Offline', value: offline, color: '#E7625F' },
      ]);
    };

    const getMachineVersionsApi = async () => {
      const result = await getMachinesVersions({
        supplierDomain,
        userGroupId: userGroup.userGroupId,
      });
      setMachineVersions(result);
    };
    getMachineStatuses();
    getMachineVersionsApi();
    console.log('usergroup change');
  }, [userGroup]); // run only once

  const handleMachineStatusClick = () => {
    console.log('wooop');
  };

  const handleMachineVersionClick = type => {
    console.log('handleMachineVersionClick', type);
  };

  return (
    <MainLayout>
      <Box sx={{ width: '100%' }}>
        <Stack>
          <Item>
            <Title>Machine Online Status</Title>
            <ChartDiv onClick={handleMachineStatusClick}>
              <PieChart
                sx={{
                  '--ChartsLegend-rootOffsetX': '-300px',
                  '--ChartsLegend-itemMarkSize': '15px',
                }}
                series={[
                  {
                    data: kioskStatuses,
                    innerRadius: 40,
                    outerRadius: 80,
                    cx: 100,
                  },
                ]}
                width={600}
                height={200}
              />
            </ChartDiv>
          </Item>
          <Item>
            <Title>Machine Versions</Title>
            {Object.keys(machineVersions).map(machineType => {
              const content = machineVersions[machineType];
              const versionData = content.map(({ version, count }) => ({
                label: version,
                value: count,
              }));

              return (
                <ChartDiv onClick={() => handleMachineVersionClick(machineType)}>
                  <Typography>{machineType}</Typography>
                  <PieChart
                    sx={{
                      '--ChartsLegend-rootOffsetX': '-300px',
                      '--ChartsLegend-itemWidth': '500px',
                      '--ChartsLegend-itemMarkSize': '15px',
                    }}
                    series={[
                      {
                        data: versionData,
                        innerRadius: 40,
                        outerRadius: 80,
                        cx: 100,
                      },
                    ]}
                    width={600}
                    height={200}
                  />
                </ChartDiv>
              );
            })}
          </Item>
        </Stack>
      </Box>
    </MainLayout>
  );
};

export default Overview;
