import React from 'react';
import Navigation from './Navigation';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
`;

const DashboardLayout = props => (
  <Root>
    <Navigation />
    {props.children}
  </Root>
);

export default DashboardLayout;
