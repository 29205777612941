import React from 'react';
import { LineChart } from 'recharts/lib/chart/LineChart';
import { Line } from 'recharts/lib/cartesian/Line';
import { YAxis } from 'recharts/lib/cartesian/YAxis';
import { XAxis } from 'recharts/lib/cartesian/XAxis';
import { CartesianGrid } from 'recharts/lib/cartesian/CartesianGrid';
import { Tooltip } from 'recharts/lib/component/Tooltip';
import styled from 'styled-components';
import formatDate from 'date-fns/format';
import { STATUS } from '../constants';

const Root = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

const Region = styled.div`
  margin-bottom: 20px;
`;

const Limit = styled.div`
  font-size: 20px;
`;

const ChartWrapper = styled.div`
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
`;

const DANGER_THRESHOLD = 0.8;
const WARNING_THRESHOLD = 0.5;

const getStatus = (execution, executionStats) => {
  const maxExecutions = Math.max(executionStats.map(({ Count }) => Count));
  if (execution > maxExecutions * DANGER_THRESHOLD) {
    return STATUS.DANGER;
  } else if (execution > maxExecutions * WARNING_THRESHOLD) {
    return STATUS.WARNING;
  }
  return STATUS.OK;
};

const Status = styled(({ status, ...props }) => <div {...props} />)`
  background: ${props =>
    props.status === STATUS.DANGER
      ? 'rgba(255,0,0,0.3)'
      : props.status === STATUS.WARNING
      ? 'rgba(0,255,255,0.3)'
      : 'rgba(0,255,0,0.3)'};
  margin-bottom: 20px;
  display: flex;
  align-items: start;
  padding: 10px;
  flex-direction: column;

  span {
    margin-bottom: 10px;
  }
`;

const LambdaCharts = ({ region, data }) => {
  const { unreservedConcurrentExecutions, unreservedConcurrentExecutionStats } = data;
  const status = getStatus(unreservedConcurrentExecutions, unreservedConcurrentExecutionStats);

  return (
    <Root>
      <Region>{region}</Region>

      <ChartWrapper>
        <Status status={status}>
          <span>STATUS: {status.toUpperCase()}</span>
          <Limit>Upper limit: {unreservedConcurrentExecutions}</Limit>
        </Status>
        <LineChart
          width={700}
          height={400}
          data={unreservedConcurrentExecutionStats.map(datum => ({
            ...datum,
          }))}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Timestamp"
            height={60}
            tickFormatter={value => formatDate(value, 'HH:mm')}
            angle={-45}
            style={{
              textAnchor: 'end',
            }}
          />
          <YAxis />
          <Tooltip
            formatter={value => `Concurrent Executions: ${value}`}
            labelFormatter={value => `Time: ${formatDate(value, 'HH:mm')}`}
            labelStyle={{
              color: 'black',
            }}
          />
          <Line type="monotone" dataKey="Count" stroke="#ffb95c" dot={false} />
        </LineChart>
      </ChartWrapper>
    </Root>
  );
};

export default LambdaCharts;
