import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ApprovalEnvironment from '../components/Approvals/ApprovalEnvironment';
import { getCurrentUser } from '../services/cognito';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h3`
  text-align: center;
`;

const ApprovalScreen = () => {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const getUserInfo = async () => {
      const result = await getCurrentUser();
      setUserInfo(result);
    };

    getUserInfo();
  }, []);

  return (
    <Root>
      <Header>Approvals</Header>
      <ApprovalEnvironment userInfo={userInfo} />
    </Root>
  );
};

export default ApprovalScreen;
