import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import styled, { css } from 'styled-components';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { BuildUrlItem } from '../BuildUrlItem';
import VersionSelect from '../../VersionSelect';
import { getPipelineUrl } from '../../../utils/index';
import BranchSelect from '../../BranchSelect';

const Root = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const Record = styled(Paper)`
  && {
    border-radius: 0px;
  }
`;

const StyledTh = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Th {...props}>{children}</Th>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  .MuiTypography-body1 {
    font-weight: 600;
  }
`;

const StyledTd = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Td {...props}>{children}</Td>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;

const StyledTable = styled(Table)`
  padding: 10px;

  &.responsiveTable tbody tr {
    border: none !important;
  }
`;

const StyledThead = styled(Thead)`
  padding-bottom: 10px;
`;

const ErrorText = styled(Typography)`
  font-weight: 600;
`;

const ActionField = ({
  record,
  versions,
  version,
  setVersion,
  handleBuildKiosk,
  handleCancelPipeline,
  branches,
  handleBranchSelect,
}) => {
  const { buildStatus = 'None', pipelineNo = null, branch } = record;

  if (['starting', 'failed'].includes(buildStatus)) {
    return (
      <StyledTh width={200}>
        <Grid container spacing={1} direction="column">
          {buildStatus === 'failed' && (
            <Grid item>
              <ErrorText>Pipeline Failed</ErrorText>
            </Grid>
          )}
          {pipelineNo ? (
            <>
              <Grid item>
                <Typography>
                  <a href={getPipelineUrl({ pipelineNo, project: 'imagine-online' })} target="_">
                    Check pipeline progress
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleCancelPipeline({ branch, pipelineNo })}
                >
                  Cancel Pipeline
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <Typography>Pipeline in progress</Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleBuildKiosk}>
                  Retry Build
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </StyledTh>
    );
  }
  return (
    <Grid container spacing={1} direction="column">
      <>
        <Grid item>
          <VersionSelect
            environment={branch}
            versions={versions}
            version={version}
            onVersionSelect={setVersion}
            setVersion={setVersion}
          />
        </Grid>
        <Grid item>
          <BranchSelect branches={branches} onBranchSelect={handleBranchSelect} />
        </Grid>
        {version ? (
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleBuildKiosk}>
              Build Kiosk
            </Button>
          </Grid>
        ) : (
          <Grid item>
            <Typography>Please select a version to build</Typography>
          </Grid>
        )}
      </>
    </Grid>
  );
};

const BuildRow = ({
  record = {},
  userInfo,
  versions,
  handleBuildKiosk,
  handleCancelPipeline,
  item = {},
  branches = [],
}) => {
  const { environment, version = '' } = record;
  const [rowVersion, setRowVersion] = useState(version);
  const [selectedBranch, setSelectedBranch] = useState('');

  const handleClickBuildKiosk = () => {
    handleBuildKiosk({
      branch: selectedBranch !== '' ? selectedBranch : environment,
      version: rowVersion,
      supplierCode: environment,
    });
  };

  const handleBranchSelect = (_, { name }) => {
    setSelectedBranch(name);
  };

  return (
    <Root>
      <Record>
        <StyledTable>
          <StyledThead>
            <Tr>
              <StyledTh width={200}>
                <Typography>Environment</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Current Version</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Build URL</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Version Select</Typography>
              </StyledTh>
            </Tr>
          </StyledThead>

          <Tbody>
            <Tr>
              <StyledTd width={200}>
                <Typography>{environment}</Typography>
              </StyledTd>
              <StyledTh width={200}>
                <Typography>{item ? item.currentVersion : 'None'}</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <StyledTd width={200}>
                  <BuildUrlItem
                    clientBuildUrl={item.clientBuildUrl}
                    currentVersion={item.currentVersion}
                    environment={environment}
                  />
                </StyledTd>
              </StyledTh>
              <StyledTd width={200}>
                <ActionField
                  environment={environment}
                  record={item}
                  versions={versions}
                  version={rowVersion}
                  setVersion={setRowVersion}
                  handleBuildKiosk={handleClickBuildKiosk}
                  handleCancelPipeline={handleCancelPipeline}
                  branches={branches}
                  handleBranchSelect={handleBranchSelect}
                />
              </StyledTd>
            </Tr>
          </Tbody>
        </StyledTable>
      </Record>
    </Root>
  );
};

export default BuildRow;
