import Typography from '@mui/material/Typography';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LinkIcon from '@mui/icons-material/Link';
import { Tbody, Tr, Td } from 'react-super-responsive-table';
import { isEmpty, isNil } from 'ramda';

export const BuildUrlItem = ({ environment, clientBuildUrl, currentVersion }) => {
  const localstackUrl = `https://${environment}-reimagine-kiosk-releases.s3.ap-southeast-2.amazonaws.com/${currentVersion}/reimagine-kiosk-server-${environment}-${currentVersion}.exe`;
  const kioskBgUrl = `https://${environment}-reimagine-kiosk-releases.s3.ap-southeast-2.amazonaws.com/${currentVersion}/kiosk-loading-bg-${environment}-${currentVersion}.exe`;

  return (
    <>
      {!(isEmpty(currentVersion) || isNil(currentVersion)) && (
        <Typography>
          <Tbody>
            <Tr>
              <Td>
                <a href={clientBuildUrl}>Kiosk</a>
              </Td>
              <Td>
                <CopyToClipboard text={clientBuildUrl}>
                  <button>
                    <LinkIcon />
                  </button>
                </CopyToClipboard>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <a href={localstackUrl}>Localstack</a>
              </Td>
              <Td>
                <CopyToClipboard text={localstackUrl}>
                  <button>
                    <LinkIcon />
                  </button>
                </CopyToClipboard>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <a href={kioskBgUrl}>Kiosk Loading Bg</a>
              </Td>
              <Td>
                <CopyToClipboard text={kioskBgUrl}>
                  <button>
                    <LinkIcon />
                  </button>
                </CopyToClipboard>
              </Td>
            </Tr>
          </Tbody>
        </Typography>
      )}
    </>
  );
};
