import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from 'styled-components';
import StagingApprovals from './Staging/StagingApprovals';
import ProductionApprovals from './Production/ProductionApprovals';
const Root = styled.div``;

const EnvironmentRoot = styled.div`
  margin-top: 40px;
  width: 100%;
`;

const StyledTab = styled(Tab)({
  color: 'white',
});

const ApprovalEnvironment = ({ userInfo }) => {
  const [stage, setStage] = useState(0);

  const onHandleChange = (_, value) => {
    setStage(value);
  };

  return (
    <>
      <Root>
        <Tabs value={stage} onChange={onHandleChange}>
          <StyledTab label="Staging" index={0} />
          <StyledTab label="Production" index={1} />
        </Tabs>
      </Root>
      <EnvironmentRoot>
        {stage === 0 && <StagingApprovals userInfo={userInfo} />}
        {stage === 1 && <ProductionApprovals userInfo={userInfo} />}
      </EnvironmentRoot>
    </>
  );
};

export default ApprovalEnvironment;
