import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { isNil } from 'ramda';

import { servers } from './constants';
import { getUserGroups } from '../../services/kiosk-info';
import UserGroupContext from '../../contexts/UserGroupContext';

const RetailerSelection = ({ closeOnRetailerChange }) => {
  const { setSupplierDomain, supplierDomain, setUserGroup } = useContext(UserGroupContext);
  const [userGroups, setUserGroups] = useState([]);

  const handleChange = event => {
    setSupplierDomain(event.target.value);
  };

  const handleRetailerInputChange = async event => {
    const result = await getUserGroups({ supplierDomain, searchString: event.target.value });
    if (result) setUserGroups(result);
  };

  const handleRetailerChange = (event, newValue) => {
    closeOnRetailerChange && closeOnRetailerChange();
    setUserGroup(newValue);
  };

  return (
    <Box
      sx={{
        width: 300,
        borderRadius: 2,
        padding: 5,
        backgroundColor: 'white',
        verticalAlign: 'middle',
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-radio-buttons-group-label">Server</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          onChange={handleChange}
        >
          {Object.keys(servers).map(key => (
            <MenuItem value={servers[key]}>{key}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {supplierDomain && (
        <FormControl fullWidth>
          <InputLabel id="demo-radio-buttons-group-label">Retailer</InputLabel>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            onInputChange={handleRetailerInputChange}
            onChange={handleRetailerChange}
            options={userGroups}
            getOptionLabel={option => `${option.business} - ${option.userGroupId}`}
            renderInput={params => (
              <TextField
                {...params}
                label="Search input"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
        </FormControl>
      )}
    </Box>
  );
};

export default RetailerSelection;
