import axios from 'axios';

const getDashboardApi = supplierDomain => `https://${supplierDomain}/dashboard`;

export const getUserGroups = async ({ supplierDomain, searchString }) => {
  const url = `${getDashboardApi(supplierDomain)}/user-groups/search?searchString=${searchString}`;
  const result = await axios.get(url);
  return result?.data || [];
};

export const getMachinesInfo = async ({ supplierDomain, userGroupId }) => {
  const url = `${getDashboardApi(supplierDomain)}/user-groups/${userGroupId}/machines-status`;
  const result = await axios.get(url);
  return result?.data || [];
};

export const getMachinesVersions = async ({ supplierDomain, userGroupId }) => {
  const url = `${getDashboardApi(supplierDomain)}/user-groups/${userGroupId}/machines/version`;
  const result = await axios.get(url);
  return result?.data || [];
};

export const getMachinesVersionsByUserGroup = async ({
  supplierDomain,
  userGroupId,
  storeIds = [],
}) => {
  const url = `${getDashboardApi(
    supplierDomain
  )}/user-groups/${userGroupId}/machines/version/store-summary`;
  const result = await axios.post(url, {
    storeIds,
  });
  return result?.data || [];
};

export const getMachinesOffline = async ({ supplierDomain, userGroupId }) => {
  const url = `${getDashboardApi(
    supplierDomain
  )}/user-groups/${userGroupId}/machines-status/offline`;
  const result = await axios.get(url);
  return result?.data || [];
};
