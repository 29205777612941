import React, { useState } from 'react';
import styled from 'styled-components';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const StyledFormControl = styled(FormControl)`
  margin-top: 20px;
  min-width: 120px;
`;

const BranchSelect = ({ branches, handleChange, isError = false }) => (
  <StyledFormControl fullWidth error={isError}>
    <Autocomplete
      disablePortal
      id="branch-list"
      getOptionLabel={option => option.name}
      options={branches}
      onChange={(_, newValue) => {
        handleChange(newValue);
      }}
      renderInput={params => <TextField {...params} label="Branch" />}
    />
    {isError && <FormHelperText>Please select a branch</FormHelperText>}
  </StyledFormControl>
);

const Branch = styled(Paper)`
  padding: 15px;
  && {
    border-radius: 0px;
  }
  margin-bottom: 20px;
`;

const BranchHeading = styled(Typography)`
  font-weight: 500;
  margin-bottom: 10px;
`;

const SelectBranch = ({ branches, handleBranchSelect, onRefresh }) => {
  const [branch, setBranch] = useState(null);
  const [error, setError] = useState(false);

  const handleChange = newValue => {
    setBranch(newValue);
  };

  const handleAddBranch = () => {
    setError(branch === null);
    if (branch) {
      handleBranchSelect(branch);
    }
  };

  return (
    <Branch>
      <BranchHeading variant="h5" component="div" gutterBottom>
        Branches
      </BranchHeading>
      <Typography>
        Please Select from dropdown below branches that you want to add to make a build
      </Typography>
      <BranchSelect branches={branches} handleChange={handleChange} isError={error} />
      <Grid container spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleAddBranch}>
            Add
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onRefresh}>
            Refresh
          </Button>
        </Grid>
      </Grid>
    </Branch>
  );
};

export default SelectBranch;
