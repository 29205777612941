import React, { useState, useEffect } from 'react';
import { getECSMonitoring } from '../../../services';
import ECSClusterRegion from './ECSClusterRegion';
import CircularProgress from '@mui/material/CircularProgress';
import './index.css';

const MonitoringECS = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const _getStats = async () => {
      setLoading(true);
      const { data } = await getECSMonitoring();
      setData(data);
      setLoading(false);
    };

    _getStats();
  }, []);

  return loading ? (
    <CircularProgress color={'white'} />
  ) : (
    <div>
      {data.map(elem => (
        <ECSClusterRegion key={elem.region} region={elem.region} clusters={elem.clusters} />
      ))}
    </div>
  );
};

export default MonitoringECS;
