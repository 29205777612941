import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNil, isEmpty, find, propEq } from 'ramda';

import {
  getDashboardApprovals,
  getJiraVersions,
  getKioskBuild,
  initiateKioskBuildByBranch,
  cancelPipeline,
  addBranch,
  getGitlabStableBranches,
} from '../../../services';

import BuildRow from './SupplierBuildRow';

// eslint-disable-next-line import/no-webpack-loader-syntax
import AxiosWorker from 'workerize-loader!../../../workers/axios-worker';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SupplierBuild = ({ userInfo, type }) => {
  const [approvals, setApprovals] = useState([]);
  const [versions, setVersions] = useState([]);
  const [builds, setBuilds] = useState([]);
  const [worker, setWorker] = useState(null);
  const [branches, setSBranches] = useState([]);

  const getApprovals = async () => {
    const result = await getDashboardApprovals(type).catch(() => ({}));
    setApprovals(result.data || []);
  };

  const getVersions = async () => {
    const result = await getJiraVersions();
    setVersions(result.data || []);
  };

  const getCallKioskBuild = async type => {
    const result = await getKioskBuild(type);
    setBuilds(result.data || []);
  };

  const getStableBranches = async () => {
    const { data = [] } = await getGitlabStableBranches();
    const branchesMod = [{ name: 'develop' }, ...data];
    setSBranches(branchesMod || []);
  };

  const handleCancelPipeline = async ({ pipelineNo, branch }) => {
    await cancelPipeline({ branch, pipelineNo });
    getKioskBuild();
  };

  const handleBuildKiosk = async ({ branch, version = '', supplierCode = '' }) => {
    // add branch if it doesnt exist yet
    const exist = find(propEq('branch', supplierCode), builds);
    const type = supplierCode.includes('stg') ? 'stg' : 'prod';
    if (!exist) {
      await addBranch({ branch });
      getCallKioskBuild(type);
    }

    await initiateKioskBuildByBranch({ branch, version, supplierCode });
    getKioskBuild();
  };

  useEffect(() => {
    const axiosWorkerInstance = AxiosWorker();
    setWorker(axiosWorkerInstance);

    const axiosWorkerListener = axiosWorkerInstance.addEventListener('message', message => {
      if (message.data && message.data.type === 'axios' && message.data.data) {
        const { data } = message.data;
        setBuilds(data);
      }
    });

    getStableBranches();

    return () => {
      axiosWorkerInstance.removeEventListener('message', axiosWorkerListener);
    };
  }, []);

  useEffect(() => {
    getApprovals();
    getVersions();
    getKioskBuild();
    getCallKioskBuild(type);

    if (!isNil(userInfo) && !isEmpty(userInfo) && !isNil(worker)) {
      const interval = setInterval(() => {
        worker.getKioskBuild(userInfo.jwtToken, type);
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [type, userInfo]);

  return (
    <Root>
      {approvals.map(approval => {
        const item = builds.find(build => build.branch === approval.environment);
        return (
          <BuildRow
            key={approval.environment}
            record={approval}
            userInfo={userInfo}
            versions={versions}
            item={item}
            handleBuildKiosk={handleBuildKiosk}
            handleCancelPipeline={handleCancelPipeline}
            branches={branches}
          />
        );
      })}
    </Root>
  );
};

export default SupplierBuild;
