import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LaptopIcon from '@mui/icons-material/Laptop';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ApiIcon from '@mui/icons-material/Api';
import PrintIcon from '@mui/icons-material/Print';

import MainLayout from './MainLayout';
import { getMachinesOffline } from '../../services/kiosk-info';
import UserGroupContext from '../../contexts/UserGroupContext';

const Item = styled(Paper)`
  background-color: white
  padding: 5px;
  margin: 5px;
`;
const HeadingLabel = styled.div`
  font-weight: bold;
  font-size: 14pt;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: red;
  padding-left: 10px;
  padding-top: 10px;
`;

const getIcon = type => {
  switch (type) {
    case 'DCM':
      return <SupportAgentIcon />;
    case 'RKS':
      return <ApiIcon />;
    case 'SSP':
      return <PrintIcon />;
    default:
      return <LaptopIcon />;
  }
};

const MachinesOffline = () => {
  const { supplierDomain, userGroup } = useContext(UserGroupContext);
  const [machinesOffline, setMachinesOffline] = useState([]);

  useEffect(() => {
    const callGetMachinesOfflineApi = async () => {
      const result = await getMachinesOffline({
        supplierDomain,
        userGroupId: userGroup.userGroupId,
      });

      console.log(result);
      setMachinesOffline(result);
    };

    callGetMachinesOfflineApi();
  }, [userGroup]); // run only once

  return (
    <MainLayout>
      <Container>
        <Grid container spacing={2}>
          {Object.keys(machinesOffline).map(key => {
            const machines = machinesOffline[key];
            return (
              <Item>
                <HeadingLabel>User Group Id: {key}</HeadingLabel>
                <List>
                  {machines.map(({ machineId, machineName, machineType }) => (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{getIcon(machineType)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={machineName}
                        secondary={`${machineId}, ${machineType}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Item>
            );
          })}
        </Grid>
      </Container>
    </MainLayout>
  );
};

export default MachinesOffline;
