import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import VideoLabelicon from '@mui/icons-material/VideoLabel';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DetailsIcon from '@mui/icons-material/Details';
import { NavLink } from 'react-router-dom';

const SideNavigation = () => (
  <List
    sx={{ width: '100%', maxWidth: 200, bgcolor: 'background.paper' }}
    component="nav"
    aria-labelledby="nested-list-subheader"
  >
    <ListItemButton component={NavLink} to="/dashboard/kiosk-info">
      <ListItemIcon>
        <VideoLabelicon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton sx={{ pl: 4 }} component={NavLink} to="/dashboard/kiosk-info/details">
      <ListItemIcon>
        <DetailsIcon />
      </ListItemIcon>
      <ListItemText primary="Versions" />
    </ListItemButton>
  </List>
);

export default SideNavigation;
