import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from 'react';

const DeploymentStatus = ({
  deploymentEnvironment,
  environment,
  commitHash,
  deploymentStatus,
  onDeploy,
  onComplete,
  onFail,
  autoDeploy = true,
}) => {
  if (deploymentStatus === 'starting') {
    if (deploymentEnvironment === 'reimagine') {
      return <Typography>In progress</Typography>;
    }
    return (
      <Typography>
        <Button
          onClick={() =>
            onComplete({
              deploymentEnvironment,
              environment,
              commitHash,
            })
          }
          variant="contained"
          color="primary"
        >
          Complete
        </Button>
      </Typography>
    );
  }
  if (deploymentStatus === 'retry_failed') {
    return <Typography>Retrying Failed</Typography>;
  }
  if (deploymentStatus === 'failed') {
    return (
      <Typography>
        <Button
          onClick={() =>
            onFail({
              deploymentEnvironment,
              environment,
              commitHash,
            })
          }
          variant="contained"
          color="secondary"
        >
          Retry Deployment
        </Button>
      </Typography>
    );
  }
  if (deploymentStatus === 'complete') {
    return <Typography>Completed</Typography>;
  }
  if (deploymentStatus === 'none' || deploymentStatus == null) {
    return (
      <Typography>
        <Button
          onClick={() =>
            onDeploy({
              deploymentEnvironment,
              environment,
              commitHash,
              autoDeploy,
            })
          }
          variant="contained"
          color="primary"
        >
          {deploymentEnvironment.toUpperCase()}
        </Button>
      </Typography>
    );
  }
  return <></>;
};

export default DeploymentStatus;
