import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';

const Root = styled.div`
  margin-bottom: 40px;
  padding-right: 40px;

  .MuiPaper-root {
    background: rgba(255, 255, 255, 0.1);

    .MuiTableCell-root {
      color: white !important;
    }
  }
`;

const Region = styled.div`
  margin-bottom: 20px;
`;

const ECSClusterRegion = ({ region, clusters = [] }) => {
  return (
    <Root>
      <Region>{region}</Region>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: 300,
                }}
              >
                Cluster ARN
              </TableCell>
              <TableCell align={'center'}>Less than 1</TableCell>
              <TableCell align={'center'}>Between 1 and 4</TableCell>
              <TableCell align={'center'}>More than 4</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clusters.map(cluster => (
              <TableRow>
                <TableCell
                  style={{
                    width: 300,
                  }}
                >
                  {cluster.clusterArn}
                </TableCell>
                <TableCell
                  align={'center'}
                  className={cluster.timeBuckets.lessThanOne > 0 ? 'alert-green' : ''}
                >
                  {cluster.timeBuckets.lessThanOne}
                </TableCell>
                <TableCell
                  align={'center'}
                  className={cluster.timeBuckets.oneToFour > 0 ? 'alert-yellow' : ''}
                >
                  {cluster.timeBuckets.oneToFour}
                </TableCell>
                <TableCell
                  align={'center'}
                  className={cluster.timeBuckets.moreThanFour > 0 ? 'alert-red' : ''}
                >
                  {cluster.timeBuckets.moreThanFour}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  );
};

export default ECSClusterRegion;
