import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'ramda';

import TextField from '@mui/material/TextField';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';
import Skeleton from '@mui/material/Skeleton';

import MainLayout from './MainLayout';
import MachineDetailRow from './MachineDetailRow';
import { getMachinesVersionsByUserGroup } from '../../services/kiosk-info';
import UserGroupContext from '../../contexts/UserGroupContext';

const Container = styled.div`
  width: 100%;
  padding: 10px;
  flex-direction: column;
  column-gap: 20px;
  margin-right: 50px;
`;

const FilterContainer = styled.div`
  width: calc(100% - 20px);
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
`;

const ChildContainer = styled.div`
  padding-right: 20px;
`;

const MachinesDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState([]);
  const [filterHelperText, setFilterHelperText] = useState('');
  const [storeIds, setStoreIds] = useState([]);

  const { supplierDomain, userGroup } = useContext(UserGroupContext);

  const getStoreVersionSummary = async (storeIds = []) => {
    setIsLoading(true);
    const { infos = [] } = await getMachinesVersionsByUserGroup({
      supplierDomain,
      userGroupId: userGroup.userGroupId,
      storeIds,
    });
    setSummary(infos);
    setIsLoading(false);
  };

  useEffect(() => {
    getStoreVersionSummary();
  }, []);

  const handleStoreFilterChange = event => {
    if (event.key === 'Enter') {
      const storesList = event.target.value.split(',');
      const nonInteger = storesList.filter(store => !Number.isInteger(parseInt(store)));
      const isValid = isEmpty(nonInteger) || event.target.value === '';
      setFilterHelperText(isValid ? '' : 'Please enter store id, for multiple just use comma');
      if (isValid) {
        const storesFilter = event.target.value === '' ? [] : storesList;
        setStoreIds(storesFilter);
        getStoreVersionSummary(storesFilter);
      }
      event.preventDefault();
    }
  };

  const handleReloadClick = () => {
    getStoreVersionSummary(storeIds);
  };

  return (
    <MainLayout>
      <Container>
        <ChildContainer>
          <FilterContainer>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="Store Filter"
                onKeyDown={handleStoreFilterChange}
                helperText={filterHelperText}
              />
              <Button variant="outlined" startIcon={<CachedIcon />} onClick={handleReloadClick}>
                Reload
              </Button>
            </Stack>
          </FilterContainer>
        </ChildContainer>
        <ChildContainer>
          {!isLoading ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" stickyHeader>
                <TableHead>
                  <TableRow hover>
                    <TableCell />
                    <TableCell>Store</TableCell>
                    <TableCell align="right">Kiosk</TableCell>
                    <TableCell align="right">Localstack</TableCell>
                    <TableCell align="right">DCM</TableCell>
                    <TableCell align="right">SSP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {summary &&
                    Object.keys(summary).map(storeId => (
                      <MachineDetailRow store={summary[storeId]} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              {Array(5)
                .fill(1)
                .map(() => (
                  <Skeleton />
                ))}
            </>
          )}
        </ChildContainer>
      </Container>
    </MainLayout>
  );
};

export default MachinesDetails;
