import React from 'react';
import styled from 'styled-components';
import { Route, Routes } from 'react-router';
import MonitoringECS from './ECS';
import MonitoringDashboard from './Dashboard';
import MonitoringLambda from './Lambda';

const Root = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
`;

const MonitoringContent = () => {
  return (
    <Root>
      <Routes>
        <Route path={'/dashboard/monitoring'} element={<MonitoringDashboard />} exact />
        <Route path={'/dashboard/monitoring/ecs'} element={<MonitoringECS />} />
        <Route path={'/dashboard/monitoring/lambda'} element={<MonitoringLambda />} />
        <Route path={'/dashboard/monitoring/rds'}>RDS</Route>
      </Routes>
    </Root>
  );
};

export default MonitoringContent;
