import React, { useState } from 'react';
import { isNil } from 'ramda';
import Paper from '@mui/material/Paper';
import styled, { css } from 'styled-components';
import Typography from '@mui/material/Typography';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { getPipelineUrl } from '../../utils/index';

const Root = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const Record = styled(Paper)`
  && {
    border-radius: 0px;
  }
`;

const StyledTh = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Th {...props}>{children}</Th>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  .MuiTypography-body1 {
    font-weight: 600;
  }
`;

const StyledTd = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Td {...props}>{children}</Td>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;

const StyledTable = styled(Table)`
  padding: 10px;

  &.responsiveTable tbody tr {
    border: none !important;
  }
`;

const StyledThead = styled(Thead)`
  padding-bottom: 10px;
`;

const ProgressField = ({ automationPipelineNo, handleCancelPipeline, environment }) => {
  return automationPipelineNo > 0 ? (
    <Grid container spacing={1} direction="column" textAlign="center">
      <Grid item>
        <Typography>
          <a
            href={getPipelineUrl({
              pipelineNo: automationPipelineNo,
              project: 'reimagine-automation',
            })}
            target="_"
          >
            Check pipeline progress
          </a>
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleCancelPipeline({ environment, automationPipelineNo })}
        >
          Cancel Pipeline
        </Button>
      </Grid>
    </Grid>
  ) : (
    <StyledTd textAlign="center">
      <Typography>Testing in progress</Typography>
    </StyledTd>
  );
};

const EnvironmentRow = ({ record = {}, onstartAutomationTest, handleCancelPipeline }) => {
  const {
    environment,
    currentVersion,
    deploymentStatusReimagine,
    automationReportUrl,
    testingStatus = 'none',
    automationPipelineNo = -1,
  } = record;

  console.log('testingStatus', testingStatus);

  const [loading, setLoading] = useState(false);

  const onHandleStartTestRequest = async () => {
    try {
      setLoading(true);
      await onstartAutomationTest(environment);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Root>
      <Record>
        <StyledTable>
          <StyledThead>
            <Tr>
              <StyledTh width={200}>
                <Typography>Environment</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Version</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Url</Typography>
              </StyledTh>
              <StyledTh textAlign="center">
                <Typography>Actions</Typography>
              </StyledTh>
            </Tr>
          </StyledThead>
          <Tbody>
            <Tr>
              <StyledTd width={150}>
                <Typography>{environment}</Typography>
              </StyledTd>
              <StyledTd width={150}>
                <Typography>{currentVersion}</Typography>
              </StyledTd>
              <StyledTd width={150}>
                <Typography>
                  {!isNil(automationReportUrl) && !isNil(automationReportUrl) ? (
                    <a href={automationReportUrl} target="_blank" rel="noreferrer">
                      {automationReportUrl}
                    </a>
                  ) : (
                    'None'
                  )}
                </Typography>
              </StyledTd>
              <>
                {deploymentStatusReimagine === 'none' &&
                  ['none', 'complete'].indexOf(testingStatus) !== -1 && (
                    <StyledTd textAlign="center">
                      <Button
                        onClick={onHandleStartTestRequest}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        Start Test
                      </Button>
                    </StyledTd>
                  )}
                {deploymentStatusReimagine === 'none' && testingStatus === 'error' && (
                  <StyledTd textAlign="center">
                    <Button
                      onClick={onHandleStartTestRequest}
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      Retry Test
                    </Button>
                  </StyledTd>
                )}
                {testingStatus === 'starting' && (
                  <ProgressField
                    automationPipelineNo={automationPipelineNo}
                    environment={environment}
                    handleCancelPipeline={handleCancelPipeline}
                  />
                )}
              </>
            </Tr>
          </Tbody>
        </StyledTable>
      </Record>
    </Root>
  );
};

export default EnvironmentRow;
