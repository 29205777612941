import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNil, isEmpty } from 'ramda';
import Alert from '@mui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';
import {
  getDashboardApprovals,
  getJiraVersions,
  prepareMergeRequest,
  acceptMergeRequest,
  checkMergeRequest,
} from '../../../services';
import ApprovalRow from './ApprovalRow';
// eslint-disable-next-line import/no-webpack-loader-syntax
import AxiosWorker from 'workerize-loader!../../../workers/axios-worker';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StagingApprovals = ({ userInfo }) => {
  const [approvals, setApprovals] = useState([]);
  const [versions, setVersions] = useState([]);
  const [worker, setWorker] = useState(null);

  const getApprovals = async () => {
    const result = await getDashboardApprovals('staging').catch(() => ({}));
    setApprovals(result.data || []);
  };

  useEffect(() => {
    getApprovals();

    const getVersions = async () => {
      const result = await getJiraVersions();
      setVersions(result.data || []);
    };
    getVersions();

    const axiosWorkerInstance = AxiosWorker();
    setWorker(axiosWorkerInstance);
    const axiosWorkerListener = axiosWorkerInstance.addEventListener('message', message => {
      if (message.data && message.data.type === 'axios' && message.data.data) {
        setApprovals(message.data.data || []);
      }
    });

    return () => {
      axiosWorkerInstance.removeEventListener('message', axiosWorkerListener);
    };
  }, []);

  useEffect(() => {
    if (!isNil(userInfo) && !isEmpty(userInfo) && !isNil(worker)) {
      const interval = setInterval(() => {
        worker.getApprovals('staging', userInfo.jwtToken);
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [userInfo, worker]);

  const [error, setError] = useState(undefined);

  const onPrepareMergeRequest = async ({ environment, version, isProduction }) => {
    await prepareMergeRequest({ environment, version, isProduction }).catch(e => {
      setError(e.response.data.message);
    });
    await getApprovals();
  };

  const onAcceptMergeRequest = async ({ environment, isProduction }) => {
    await acceptMergeRequest({ environment, isProduction }).catch(e => {
      setError(e.response.data.message);
    });
    await getApprovals();
  };

  const onCheckMergeRequest = async ({ environment, isProduction }) => {
    await checkMergeRequest({ environment, isProduction }).catch(e => {
      setError(e.response.data.message);
    });
    await getApprovals();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(undefined);
  };

  return (
    <Root>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
      {approvals.map(approval => (
        <ApprovalRow
          key={approval.environment}
          userInfo={userInfo}
          versions={versions}
          record={approval}
          onPrepareMergeRequest={onPrepareMergeRequest}
          onAcceptMergeRequest={onAcceptMergeRequest}
          onCheckMergeRequest={onCheckMergeRequest}
        />
      ))}
    </Root>
  );
};

export default StagingApprovals;
