import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const StyledFormControl = styled(FormControl)`
  margin-top: 20px;
  min-width: 120px;
`;

const BranchSelect = ({ environment, branches, branch, onBranchSelect }) => {
  const [selectedBranch, setSelectedBranch] = useState({});

  useEffect(() => {
    const valueIndex = branches.findIndex(({ name }) => name === branch);
    if (valueIndex >= 0) {
      setSelectedBranch(branches[valueIndex]);
    }
    if (branches[valueIndex]) {
      onBranchSelect(environment, branches[valueIndex]);
    }
  }, [branch, branches]);

  const handleChange = value => {
    setSelectedBranch(value);
    onBranchSelect(environment, value);
  };

  return (
    <StyledFormControl fullWidth>
      <Autocomplete
        id="branch-list"
        value={isEmpty(selectedBranch) ? null : selectedBranch}
        getOptionLabel={option => option.name}
        options={branches}
        onChange={(_, newValue) => {
          handleChange(newValue);
        }}
        renderInput={params => <TextField {...params} label="Branch" />}
      />
    </StyledFormControl>
  );
};

export default BranchSelect;
