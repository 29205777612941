import React, { useState } from 'react';
import { groupBy, uniq } from 'ramda';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';

const machineTypes = ['K', 'RKS', 'DCM', 'SSP'];

const MachineDetailRow = ({ store }) => {
  const [open, setOpen] = useState(false);

  const [first] = store;
  const byMachineType = groupBy(({ machineType }) => machineType);
  const grouped = byMachineType(store);
  const getVersionType = type => {
    if (!grouped[type]) return '';
    return uniq(grouped[type].map(({ version }) => (version ? version : 'N/A'))).join(',');
  };
  const kioskLists = store.filter(({ machineType }) => machineType === 'K');
  return (
    <>
      <TableRow
        hover
        key={first.userGroupId}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {`${first.userGroupId} - ${first.business}`}
        </TableCell>
        {machineTypes.map(type => (
          <TableCell align="right">{getVersionType(type)}</TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Kiosk Machines
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Kiosk</TableCell>
                    <TableCell>Version</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kioskLists.map(({ machineName, version, machineId }) => (
                    <TableRow key={machineId}>
                      <TableCell component="th" scope="row">
                        {machineName}
                      </TableCell>
                      <TableCell>{`${version ? version : 'N/A'}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default MachineDetailRow;
