import React, { useEffect, useState } from 'react';
import { Route, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { isAuthenticated } from '../services/cognito';
import DashboardLayout from './DashboardLayout';

// const PrivateRoute = ({ children }) => {
//   const authed = true// isauth() returns true or false based on localStorage

//   return authed ? children : <Navigate to="/" />;
// }

const PrivateRoute = ({ children }) => {
  // return <Redirect to="/" />;
  const [isLoading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const check = async () => {
      const result = await isAuthenticated();
      console.log('PrivateRouter isAutheticated', result);

      setLoading(false);
      setRedirect(!result);
    };
    check();
  }, []);

  return redirect ? (
    <Navigate to="/login" />
  ) : (
    <>{isLoading ? <CircularProgress /> : <DashboardLayout>{children}</DashboardLayout>}</>
  );
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to / page
};

export default PrivateRoute;
