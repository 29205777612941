import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getPipelineUrl } from '../../utils/index';
import VersionSelect from '../VersionSelect';
import { BuildUrlItem } from './BuildUrlItem';

const Record = styled(Paper)`
  && {
    border-radius: 0px;
  }
  padding: 10px;
  margin-bottom: 15px;
`;

const StyledTable = styled(Table)`
  padding: 10px;
  border-collapse: collapse;
`;

const StyledThead = styled(Thead)`
  padding-bottom: 10px;
`;

const StyledTh = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Th {...props}>{children}</Th>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  .MuiTypography-body1 {
    font-weight: 600;
  }
`;

const StyledTd = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Td {...props}>{children}</Td>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;

const ErrorText = styled(Typography)`
  font-weight: 600;
`;

const ActionField = ({
  record,
  versions,
  version,
  setVersion,
  handleBuildKiosk,
  handleHideBranch,
  handleCancelPipeline,
  useStable,
  setUseStable,
}) => {
  const { buildStatus = 'None', pipelineNo = null, branch } = record;

  if (['starting', 'failed'].includes(buildStatus)) {
    return buildStatus === 'starting' ? (
      <StyledTh width={200}>
        {pipelineNo ? (
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography>
                <a href={getPipelineUrl({ pipelineNo, project: 'imagine-online' })} target="_">
                  Check pipeline progress
                </a>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleCancelPipeline({ branch, pipelineNo })}
              >
                Cancel Pipeline
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Typography>Pipeline in progress</Typography>
        )}
      </StyledTh>
    ) : (
      <Grid container spacing={1} direction="column">
        <Grid item>
          <ErrorText>Pipeline Failed</ErrorText>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleBuildKiosk}>
            Retry Build
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={1} direction="column">
      {branch === 'develop' && (
        <>
          <Grid item>
            <VersionSelect
              environment={branch}
              versions={versions}
              version={version}
              onVersionSelect={setVersion}
              setVersion={setVersion}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useStable}
                  onChange={() => {
                    setUseStable(!useStable);
                  }}
                />
              }
              label="Build from Stable Branch"
            />
          </Grid>
          {version ? (
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleBuildKiosk}>
                Build Kiosk
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Typography>Please select a version to build</Typography>
            </Grid>
          )}
        </>
      )}

      {branch !== 'develop' && (
        <>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleBuildKiosk}>
              Build Kiosk
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="error" onClick={handleHideBranch}>
              Hide Branch
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const BuildListItem = ({
  record,
  handleBuildKiosk,
  handleHideBranch,
  handleCancelPipeline,
  versions,
}) => {
  const [version, setVersion] = useState(record?.version || '');
  const [useStable, setUseStable] = useState(false);

  const { currentVersion = 'None', pendingVersion = 'None', branch, clientBuildUrl = '' } = record;

  const handleClickBuildKiosk = () =>
    handleBuildKiosk({
      branch: useStable ? `stable/${version}` : record.branch,
      version,
      supplierCode: 'dev-k-1',
    });
  const handleClickHidedKiosk = () => handleHideBranch(record);
  return (
    <Record>
      <StyledTable>
        <StyledThead>
          <Tr>
            <StyledTh width={200}>
              <Typography>Environment</Typography>
            </StyledTh>
            <StyledTh width={200}>
              <Typography>Latest Version</Typography>
            </StyledTh>
            <StyledTh width={200}>
              <Typography>Pending Build Version</Typography>
            </StyledTh>
            <StyledTh width={200}>
              <Typography>Build URL</Typography>
            </StyledTh>
            <StyledTh width={200}>
              <Typography>Actions</Typography>
            </StyledTh>
          </Tr>
        </StyledThead>
        <Tbody>
          <Tr>
            <StyledTd width={200}>
              <Typography>{branch}</Typography>
            </StyledTd>
            <StyledTd width={200}>
              <Typography>{currentVersion}</Typography>
            </StyledTd>
            <StyledTd width={200}>
              <Typography>{pendingVersion}</Typography>
            </StyledTd>
            <StyledTd width={200}>
              <BuildUrlItem
                clientBuildUrl={clientBuildUrl}
                currentVersion={currentVersion}
                environment="dev-k-1"
              />
            </StyledTd>
            <StyledTd width={200}>
              <ActionField
                record={record}
                versions={versions}
                version={version}
                setVersion={setVersion}
                handleBuildKiosk={handleClickBuildKiosk}
                handleHideBranch={handleClickHidedKiosk}
                handleCancelPipeline={handleCancelPipeline}
                useStable={useStable}
                setUseStable={setUseStable}
              />
            </StyledTd>
          </Tr>
        </Tbody>
      </StyledTable>
    </Record>
  );
};

export default BuildListItem;
