import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Root = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid white;
  padding-right: 15px;
  padding-top: 25px;
`;

const Nav = styled(NavLink)`
  color: white;
  margin-right: 10px;
  text-decoration: none;
  border: 3px solid white;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;

  &.active {
    background-color: rgba(255, 255, 255, 0.15);
  }

  margin-bottom: 20px;
  margin-left: 10px;
`;

const MonitoringSideNavigation = () => {
  return (
    <Root>
      <Nav to={'/dashboard/monitoring'} exact>
        Dashboard
      </Nav>
      <Nav to={'/dashboard/monitoring/ecs'}>ECS</Nav>
      <Nav to={'/dashboard/monitoring/lambda'}>Lambda</Nav>
      <Nav to={'/dashboard/monitoring/rds'}>RDS</Nav>
    </Root>
  );
};

export default MonitoringSideNavigation;
