import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled, { css } from 'styled-components';
import Typography from '@mui/material/Typography';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Button from '@mui/material/Button';
import VersionSelect from '../../VersionSelect';

const Root = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const Record = styled(Paper)`
  && {
    border-radius: 0px;
  }
`;

const StyledAccordion = styled(Accordion)`
  && {
    &.Mui-expanded {
      margin-top: 0px;
    }
    &:last-child {
      border-radius: 0px;
    }
  }
`;

const StyledTh = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Th {...props}>{children}</Th>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  .MuiTypography-body1 {
    font-weight: 600;
  }
`;

const StyledTd = styled(({ textAlign, maxWidth, children, ...props }) => (
  <Td {...props}>{children}</Td>
))`
  text-align: left;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;

const StyledTable = styled(Table)`
  padding: 10px;

  &.responsiveTable tbody tr {
    border: none !important;
  }
`;

const StyledThead = styled(Thead)`
  padding-bottom: 10px;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    flex-direction: column;
    max-height: 100px;
    overflow: auto;
  }
`;

const ApprovalRow = ({
  record = {},
  versions,
  onPrepareMergeRequest,
  onAcceptMergeRequest,
  onCheckMergeRequest,
  userInfo,
}) => {
  const {
    environment,
    currentCommit = {},
    audit = [],
    currentVersion,
    deploymentStatusReimagine,
    pendingVersion,
    pendingProductionVersion,
  } = record;
  const [version, setVersion] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const onHandlePrepareMergeRequest = async () => {
    if (version) {
      try {
        setLoading(true);
        await onPrepareMergeRequest({ environment, version });
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  };

  const onHandleAcceptMergeRequest = async () => {
    try {
      setLoading(true);
      await onAcceptMergeRequest({ environment });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onHandleMergeProduction = async () => {
    try {
      setLoading(true);
      await onPrepareMergeRequest({ environment, isProduction: true });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onHandleCheckMergeRequest = async () => {
    try {
      setLoading(true);
      await onCheckMergeRequest({ environment });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onHandleCheckProductionMergeRequest = async () => {
    try {
      setLoading(true);
      await onCheckMergeRequest({ environment, isProduction: true });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onHandleAcceptProductionMergeRequest = async () => {
    try {
      setLoading(true);
      await onAcceptMergeRequest({ environment, isProduction: true });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onVersionSelect = value => {
    setVersion(value);
  };

  const allowedToApprove = userInfo.canApprove;

  return (
    <Root>
      <Record>
        <StyledTable>
          <StyledThead>
            <Tr>
              <StyledTh width={200}>
                <Typography>Environment</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Commit</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Version</Typography>
              </StyledTh>
              <StyledTh width={200}>
                <Typography>Pending Version</Typography>
              </StyledTh>
              <StyledTh textAlign="center">
                <Typography>Actions</Typography>
              </StyledTh>
            </Tr>
          </StyledThead>
          <Tbody>
            <Tr>
              <StyledTd width={200}>
                <Typography>{environment}</Typography>
              </StyledTd>
              <StyledTd width={200}>
                <a href={currentCommit.commitUrl} target="_blank" rel="noreferrer">
                  <Typography>View Commit</Typography>
                </a>
              </StyledTd>
              <StyledTd width={200}>
                <Typography>{currentVersion}</Typography>
              </StyledTd>
              <StyledTd width={200}>
                <Typography>{pendingVersion || 'None'}</Typography>
              </StyledTd>
              <>
                {allowedToApprove && deploymentStatusReimagine === 'none' && (
                  <StyledTd textAlign="center">
                    <VersionSelect
                      loading={loading}
                      environment={environment}
                      versions={versions}
                      onVersionSelect={onVersionSelect}
                      version={version}
                    />
                    <Button
                      onClick={onHandlePrepareMergeRequest}
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      Prepare MR
                    </Button>
                  </StyledTd>
                )}
                {allowedToApprove && deploymentStatusReimagine === 'merge_request_ready' && (
                  <StyledTd textAlign="center">
                    <Button
                      onClick={onHandleAcceptMergeRequest}
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      Accept MR
                    </Button>
                  </StyledTd>
                )}
                {allowedToApprove &&
                  deploymentStatusReimagine === 'merge_production_request_ready' && (
                    <StyledTd textAlign="center">
                      <Button
                        onClick={onHandleAcceptProductionMergeRequest}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        Accept Production MR
                      </Button>
                    </StyledTd>
                  )}
                {allowedToApprove && deploymentStatusReimagine === 'check_merge_request' && (
                  <StyledTd textAlign="center">
                    <Button
                      onClick={onHandleCheckMergeRequest}
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      Check MR
                    </Button>
                  </StyledTd>
                )}
                {allowedToApprove &&
                  (deploymentStatusReimagine === 'waiting_for_pipeline' ||
                    deploymentStatusReimagine === 'waiting_for_production_pipeline') && (
                    <StyledTd textAlign="center">
                      <Typography>Pipeline in progress</Typography>
                    </StyledTd>
                  )}

                {allowedToApprove && deploymentStatusReimagine === 'check_production_request' && (
                  <StyledTd textAlign="center">
                    <Button
                      onClick={onHandleCheckProductionMergeRequest}
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      Check Production MR
                    </Button>
                  </StyledTd>
                )}
                {allowedToApprove &&
                  currentVersion !== pendingProductionVersion &&
                  !pendingVersion &&
                  deploymentStatusReimagine.indexOf('production') === -1 && (
                    <StyledTd width={200}>
                      <Button
                        onClick={onHandleMergeProduction}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        Merge Prod
                      </Button>
                    </StyledTd>
                  )}
              </>
            </Tr>
          </Tbody>
        </StyledTable>
      </Record>
      {audit.length ? (
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>History</Typography>
          </AccordionSummary>
          <StyledAccordionDetails>
            {audit.map(history => (
              <Typography key={history.timestamp}>
                {new Date(history.timestamp).toLocaleString()} - {history.user} initiated{' '}
                {String(history.action).toUpperCase()} action for commit{' '}
                {history.commitHash.substring(0, 8)}
              </Typography>
            ))}
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : null}
    </Root>
  );
};

export default ApprovalRow;
